import React, { useState, useEffect, Suspense } from "react";
import { apiService } from "../../api/dataverseApi";
import { createUser, deleteB2CUser } from "../../api/B2CApi";
import PasswordChangeModal from "./passwordChangeModal";

const ManageUsers = (props) => {
  const { subscriptions, mainAccountContact } = props;
  const [showUserForm, setShowUserForm] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showPasswordRequirements, setShowPasswordRequirements] = useState(false);
  const [licenseInfo, setLicenseInfo] = useState({
    totalLicenses: 0,
    availableLicenses: 0,
  });
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //for password modal
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showSubscribeButton, setShowSubscribeButton] = useState(true);

  useEffect(() => {
    const fetchChildContacts = async () => {
      if (!mainAccountContact?.contactid || !subscriptions) {
        console.error("Main account contact ID or subscriptions not available");
        return;
      }

      setIsLoading(true);
      try {
        const response = await apiService.getChildContacts(mainAccountContact.contactid);
        //console.log("Child contacts response:", response);

        if (response.success && response.contacts) {
          const formattedUsers = response.contacts.map((contact) => ({
            email: contact.email,
            firstName: contact.firstName,
            lastName: contact.lastName,
            contactId: contact.contactId,
            phone: contact.phone,
            status: contact.status,
          }));

          let usedLicenses = formattedUsers.length;
          const totalLicenses = subscriptions.tg_numberoflicences || 0;

          // If mainAccountContact is flagged as 9999, treat mainAccount as a "child user" too
          if (mainAccountContact.tg_flags === 9999) {
            usedLicenses += 1; 
            formattedUsers.push({
              email: mainAccountContact.emailaddress1,
              firstName: mainAccountContact.firstname,
              lastName: mainAccountContact.lastname,
              contactId: mainAccountContact.contactid,
              phone: mainAccountContact.telephone1,
            });
            setShowSubscribeButton(false);
          }

          const availableLicenses = totalLicenses - usedLicenses;

          setUsers(formattedUsers);
          setLicenseInfo({
            totalLicenses,
            availableLicenses,
          });
        } else {
          console.error("Invalid response format:", response);
          setError("Failed to load user list: Invalid response format");
        }
      } catch (error) {
        console.error("Error fetching child contacts:", error);
        setError("Failed to load user list");
      } finally {
        setIsLoading(false);
      }
    };

    fetchChildContacts();
  }, [mainAccountContact?.contactid, subscriptions]);

  const PasswordRequirements = () => (
    <div
      className="password-requirements mt-2"
      style={{ fontSize: "0.85em", color: "#666" }}
    >
      <div className="alert alert-info">
        <strong>Password Requirements:</strong>
        <ul className="mb-0 mt-1">
          <li>Must be between 8 and 256 characters</li>
          <li>
            Must contain at least 3 of the following:
            <ul>
              <li>Lowercase letters (a-z)</li>
              <li>Uppercase letters (A-Z)</li>
              <li>Numbers (0-9)</li>
              <li>
                Special characters (@ # $ % ^ &amp; * - _ + = [ ] { } | \ : ' , ? / ` ~ " ( ) ; .)
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );

  const toggleUserForm = () => {
    setShowUserForm(!showUserForm);
    setFormData({ email: "", password: "", firstName: "", lastName: "" });
    setError("");
    setSuccess("");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const addUser = async () => {
    setError("");
    setSuccess("");

    if (
      !formData.email ||
      !formData.password ||
      !formData.firstName ||
      !formData.lastName
    ) {
      setError(
        "Please provide all required fields: First Name, Last Name, Email, and Password"
      );
      return;
    }

    if (licenseInfo.availableLicenses <= 0) {
      setError("No licenses available");
      return;
    }

    if (users.some((user) => user.email === formData.email)) {
      setError("User with this email already exists");
      return;
    }

    setIsLoading(true);
    try {
      if (formData.email !== mainAccountContact?.emailaddress1) {
        const b2cResponse = await createUser(
          formData.email,
          formData.password,
          formData.firstName,
          formData.lastName
        );

        if (b2cResponse.message !== "User created successfully") {
          throw new Error("Failed to create user in Azure B2C");
        }
      }

      const NewContact = {
        firstname: formData.firstName,
        lastname: formData.lastName,
        emailaddress1: formData.email,
        statecode: 0, // active
        "tg_ParentContactID@odata.bind": `/contacts(${mainAccountContact.contactid})`,
      };

      const subscriptionData = {
        startdate: subscriptions.startdate,
        tg_expirydate: subscriptions.tg_expirydate,
        enddate: "2900-01-01T00:00:00Z",
        statecode: 0,
        emailaddress: formData.email,
        tg_numberoflicences: 1,
        isdefault: false,
        tg_subscriptionid: "151",
        "tg_Transactiondetails@odata.bind": `/tg_transactiondetails(${subscriptions._tg_transactiondetails_value})`,
      };

      const dataverseResponse = await apiService.createChildUser(
        NewContact,
        subscriptionData
      );

      if (!dataverseResponse.success && formData.email !== mainAccountContact?.emailaddress1) {
        await deleteB2CUser(formData.email);
        throw new Error(
          "Failed to create user in Dataverse. Changes have been rolled back."
        );
      }

      setUsers((prevUsers) => [
        ...prevUsers,
        {
          email: formData.email,
          firstName: formData.firstName,
          lastName: formData.lastName,
          contactId: dataverseResponse.data.contactId,
        },
      ]);
      setLicenseInfo((prev) => ({
        ...prev,
        availableLicenses: prev.availableLicenses - 1,
      }));
      setSuccess("User created successfully");
      setFormData({ email: "", password: "", firstName: "", lastName: "" });
      setShowUserForm(false);
    } catch (error) {
      console.error("Error in user creation:", error);
      setError(error.message || "Failed to create user");
    } finally {
      setIsLoading(false);
    }
  };

  const deleteUser = async (userEmail, userContactId) => {
    if (!window.confirm(`Are you sure you want to delete user ${userEmail}?`)) {
      return;
    }
    setError("");
    setSuccess("");
    setIsLoading(true);

    try {
      const isMainAccount = userEmail === mainAccountContact?.emailaddress1;
      const dataverseResponse = await apiService.deleteChildUser(
        userContactId,
        isMainAccount
      );
      if (dataverseResponse.success) {
        setShowSubscribeButton(true);
      }else{
        throw new Error("Failed to delete user from Dataverse");
      }

      // If not the main account user, also attempt B2C deletion
      if (!isMainAccount) {
        try {
          await deleteB2CUser(userEmail);
        } catch (b2cError) {
          console.error(
            "B2C deletion failed, but Dataverse deletion was successful",
            b2cError
          );
          setError(
            "Warning: User removed from system but B2C cleanup failed. Please contact support."
          );
        }
      }

      setUsers((prevUsers) => prevUsers.filter((u) => u.email !== userEmail));
      setLicenseInfo((prev) => ({
        ...prev,
        availableLicenses: prev.availableLicenses + 1,
      }));
      setSuccess("User deleted successfully");
    } catch (error) {
      console.error("Error deleting user:", error);
      setError(error.message || "Failed to delete user");
    } finally {
      setIsLoading(false);
    }
  };

  const subscribeMyself = async () => {
    setIsLoading(true);
    try {
      const subscriptionData = {
        startdate: subscriptions.startdate,
        tg_expirydate: subscriptions.tg_expirydate,
        enddate: "2900-01-01T00:00:00Z", //system required
        statecode: 0,
        emailaddress: formData.email,
        tg_numberoflicences: 1,
        isdefault: false,
        tg_subscriptionid: "151",
        "tg_Transactiondetails@odata.bind": `/tg_transactiondetails(${subscriptions._tg_transactiondetails_value})`,
      };

      const response = await apiService.mainAccountSubscirbeMyself(
        mainAccountContact.contactid,
        { tg_flags: 9999 },
        subscriptionData
      );

      if (response.success) {
        setSuccess("Successfully subscribed yourself");
        if (subscriptions) {
          subscriptions.tg_flags = 9999;
        }
        setLicenseInfo((prev) => ({
          ...prev,
          availableLicenses: prev.availableLicenses - 1,
        }));
        setUsers((prevUsers) => [
          ...prevUsers,
          {
            email: mainAccountContact.emailaddress1,
            firstName: mainAccountContact.firstname,
            lastName: mainAccountContact.lastname,
            contactId: mainAccountContact.contactid,
          },
        ]);

        setShowSubscribeButton(false);
      } else {
        setError("Failed to subscribe: " + response.message);
      }
    } catch (error) {
      setError("Failed to subscribe: " + error.message);
      console.error("Error subscribing:", error);
    } finally {
      setIsLoading(false);
    }
  };

 
  const handlePasswordUpdateSuccess = (message) => {
    setSuccess(message);
    setTimeout(() => {
      setSuccess("");
    }, 10000);
  };


  return (
    <>
      <Suspense fallback={<div className="loader"></div>}>
        <div role="tabpanel" className="tab-pane active" id="manageUsers">
          <span>
            Total licenses:{" "}
            <strong className="marginLeft3px badge" id="totalLicenses">
              {licenseInfo.totalLicenses}
            </strong>{" "}
            | Licenses available:{" "}
            <strong className="marginLeft3px badge" id="availableLicenses">
              {licenseInfo.availableLicenses}
            </strong>
          </span>

          {isLoading ? (
            <div className="loader"></div>
          ) : error ? (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          ) : (
            <table className="table table-bordered tablesorter" id="myTable">
              <thead className="thead_bg">
                <tr>
                  <th className="header">Email</th>
                  <th className="header">Name</th>
                  <th className="header"></th>
                </tr>
              </thead>
              <tbody>
                {users && users.length > 0 ? (
                  users.map((user, index) => (
                    <tr key={user.contactId || index}>
                      <td>{user.email || user.emailaddress1}</td>
                      <td>{`${user.firstName} ${user.lastName}`}</td>
                      <td>
                        <button
                          title="Delete User"
                          className="marginRight15px clearButtonStyle"
                          onClick={() => deleteUser(user.email, user.contactId)}
                        >
                          <i className="fa fa-trash-o"></i>
                        </button>
                        <span style={{  marginLeft: "10px"}}>
                          <button
                            className="clrBlue"
                            style={{  cursor: "pointer",
                              background: "none",
                              border: "none",
                              padding: 0,
                              textDecoration: "underline",
                              font: "inherit" }}
                            onClick={() => {
                              setSelectedUser(user);
                              setShowPasswordModal(true);
                            }}
                          >
                            change password
                          </button>
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="text-center">
                      No users found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}

          {!showUserForm && licenseInfo.availableLicenses > 0 && (
            <p className="text-right noBtmMarging">
              {showSubscribeButton  && (
                <button
                  type="button"
                  className="btn btn-primary btn-sm borderNone marginRight10"
                  onClick={() => subscribeMyself()}
                >
                  Subscribe myself
                </button>
              )}
              <button
                type="button"
                className="btn btn-info btn-sm borderNone"
                onClick={toggleUserForm}
              >
                Create User
              </button>
            </p>
          )}

          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          {success && (
            <div className="alert alert-success" role="alert">
              {success}
            </div>
          )}

          {showUserForm && (
            <div>
              <table className="table table-bordered">
                <thead className="thead_bg">
                  <tr>
                    <th style={{ width: "20%" }}>First Name</th>
                    <th style={{ width: "20%" }}>Last Name</th>
                    <th style={{ width: "30%" }}>Email</th>
                    <th style={{ width: "25%" }}>
                      Password
                      <i
                        className="fa fa-info-circle ml-2"
                        onMouseEnter={() => setShowPasswordRequirements(true)}
                        onMouseLeave={() => setShowPasswordRequirements(false)}
                        style={{ cursor: "help", marginLeft: "2px" }}
                      ></i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="text"
                        name="firstName"
                        value={formData.firstName || ""}
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="Enter first name"
                        required
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="lastName"
                        value={formData.lastName || ""}
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="Enter last name"
                        required
                      />
                    </td>
                    <td>
                      <input
                        type="email"
                        name="email"
                        value={formData.email || ""}
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="Enter email"
                        required
                      />
                    </td>
                    <td>
                      <input
                        type="password"
                        name="password"
                        value={formData.password || ""}
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="Enter password"
                        required
                      />
                      {showPasswordRequirements && <PasswordRequirements />}
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="text-right noBtmMarging">
                <button
                  type="button"
                  className="btn btn-default btn-sm marginRight10"
                  onClick={toggleUserForm}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-info btn-sm borderNone"
                  onClick={addUser}
                >
                  Add User
                </button>
              </p>
            </div>
          )}

          {showPasswordModal && (
            <PasswordChangeModal
              show={showPasswordModal}
              onClose={() => setShowPasswordModal(false)}
              user={selectedUser}
              //  bubble success messages into ManageUsers:
              onSuccess={(msg) => handlePasswordUpdateSuccess(msg)}
            />
          )}
        </div>
      </Suspense>
    </>
  );
};

export default ManageUsers;
