/* eslint-disable */
import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import InputField from "../../../components/InputField";
import { apiService } from "../../../api/dataverseApi";
import { subscribeToNewsletter } from "../../../api/mailchimpApi";

const AccountCreationForm = (props) => {
  const { nextSteps, contactDetails, setNewUserEmail ,setContactDetails} = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subscribeNewsletter, setSubscribeNewsletter] = useState(false);
  const [newsletterError, setNewsletterError] = useState("");

  const history = useHistory();
    const handleGoBack = () => {
      history.push("/myCart");
    };
    
  const validateForm = () => {
    if (!email || !password || !confirmPassword) {
      setError("All fields are required");
      return false;
    }
    if (password !== confirmPassword) {
      setError("Passwords do not match, please confirm your password again");
      return false;
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);
    try {
   // console.log("contactDetails", contactDetails);
    const updatedContactDetails = {
      ...contactDetails,
      emailaddress1: email  
    };

    const response = await apiService.UserRegistrationApi({ email,
        password,
        contactDetails:updatedContactDetails })
      // console.log("User creation response:", response);
      if (response.success) {
       // console.log("User created successfully, response.data", response.data);
        //send to checkout page for success checkout function use
        setContactDetails((prev) => ({
          ...prev,
          contactid: response.data.contacts.newContactId,
          nickname: response.data.contacts.nickname,
          emailaddress1: email,
        }));
        setNewUserEmail(email);
       
        if (subscribeNewsletter) {
          try {
            await subscribeToNewsletter(email);
            console.log("Successfully subscribed to newsletter");
          } catch (newsletterError) {
            console.error("Newsletter subscription error:", newsletterError);
            setNewsletterError(
              "Note: Account created successfully, but newsletter subscription failed. You can try subscribing later."
            );
          }
        }
        setTimeout(() => {
          nextSteps();
        }, 100);
      } else {
        setError(response.message || "Failed to create account");
      }
    } catch (err) {
      console.error("Error creating user:", err);
      setError(err.message || "An error occurred while creating your account");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form
      className="account-information-form checkout-steps"
      onSubmit={handleSubmit}
    >
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}

      <InputField
        label="Email"
        id="email"
        name="user.email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type="email"
        required
      />

      <InputField
        label="Password"
        id="password"
        name="user.password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        type="password"
        placeholder="Please enter your password"
        required
      />

      <InputField
        label="Confirm password"
        id="confirmPassword"
        name="user.confirmPassword"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        type="password"
        placeholder="Please confirm your password"
        required
      />
        <div className="col-lg-9 col-sm-9 col-xs-9">
          <label className="checkbox-inline">
            <input
              type="checkbox"
              id="subscribe"
              checked={subscribeNewsletter}
              onChange={(e) => setSubscribeNewsletter(e.target.checked)}
              name="user.email_news_confirmation"
            />
            Subscribe to news and product updates
          </label>
        </div>
        <div className="col-lg-9 col-sm-9 col-xs-9">
          <label className="checkbox-inline">
            <input
              type="checkbox"
              id="tg-agreement"
              value="true"
              name="user.termandcondition"
              required
            />
            I have read, understood and agree to be bound by the{" "}
            <a
              href="https://www.tg.org.au/license-agreement/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Therapeutic Guidelines licence agreement
            </a>{" "}
            terms and conditions.
          </label>
        </div>
  

      <div className="mTop12px checkout-btn-box">
        <button
          type="button"
          className="btn"
          onClick={handleGoBack}
        >
          Back to my cart
        </button>
        <button type="submit" className="btn" disabled={isSubmitting}>
          {isSubmitting ? "Creating Account..." : "Continue"}
        </button>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
});

export default connect(mapStateToProps)(AccountCreationForm);
