
export const SearchTGSynonyms = (searchText, tgg) => {
    searchText = (searchText.charAt(searchText.length - 1) === '*') ? searchText.slice(0, -1) : searchText; // remove asterisk
    const lowerCaseSearchText = searchText.toLowerCase();
    return tgg.synonyms.rows.reduce((indices, item, index) => {
        let keywords = item[0].split(',').map(keyword => keyword.trim().toLowerCase());
        if (item[1] && item[1].length > 0) {
            keywords = keywords.concat(item[1].split(',').map(keyword => keyword.trim().toLowerCase()));
        }
        if (keywords.includes(lowerCaseSearchText)) {
            indices.push(index);
        }
        return indices;
    }, []);
};


export const XPathSynonyms = (k) => {
    if (k.length === 0) return "";
    let kArray = [];
    if (k.length === 1)
        kArray = k[0];
    else {
        try {
            kArray = k.split(',');
        } catch (error) {
            console.log(error);
            kArray = [0];
        }
    }
    const kXpression = k.map((n) => {
        return `contains(k, ",${n},")`;
    });
    return ` or ${kXpression.join(' or ')}`;
};