/* eslint-disable */
import React, { useState, useEffect, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { push } from "connected-react-router";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { format, addYears, parseISO, addDays } from "date-fns";
import { apiService } from "../../api/dataverseApi";
import {
  updateSingleCart,
  updateMultiCart,
  getTotalCartQuantity,
} from "../../store/reducers/subscripion";
import { myCartLoginRequest } from "../../authConfig";
import { selectID } from "../../features/counter/counterSlice";

const NavBar = React.lazy(() => import("../../components/navBar"));
const TopicBanner = React.lazy(() => import("../../components/topicBanner"));
const Footer = React.lazy(() => import("../../components/footer"));

const MyCart = (props) => {
  const { pathname, search, push } = props;
  const [totalPrice, setTotalPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState(null);
  const dispatch = useDispatch();
  const [cartItems, setCartItems] = useState([]);
  const totalCartQuantity = useSelector(getTotalCartQuantity);
  // const { instance } = useMsal();
  const { instance, accounts } = useMsal();
  // const authID = action.payload.dartSelectors.authID;
  const authID = useSelector(selectID);
  const singleCart = useSelector((state) => state.subscripion.singleCart);
  const multiCart = useSelector((state) => state.subscripion.multiCart);

  // useEffect(() => {
  //   const xeroAuthUrl = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=34E18857FBA142C2AD6847215F1FC315&redirect_uri=https://localhost:3000/checkout/orderConfirmation&scope=openid profile email accounting.transactions accounting.settings accounting.contacts offline_access`;

  //   window.location.href = xeroAuthUrl;
  // }, []);

  useEffect(() => {
    fetchCart();
  }, []);

  const getExpirationDate = (item) => {
    if (item.ordertype === "New") {
      return format(addYears(new Date(), 1), "dd-MMM-yyyy");
    } else if (item.ordertype === "Renew" || item.ordertype === "Addon") {
      return format(
        addYears(parseISO(item.currentsubscriptionenddate), 1),
        "dd-MMM-yyyy"
      );
    }
    return "";
  };

  const fetchCart = async () => {
    setIsLoading(true);
    try {
      const items = await apiService.getCart();
      const cartItem = items.find(
        (item) => item.subscriptionid === "151" || item.subscriptionid === "168"
      );
     // console.log("---cartItem---", cartItem);
      if (!cartItem) {
        setCartItems(null);
        return;
      }
      if (cartItem) {
        const { description, startDate, endDate } =
          handleSubscriptionDatesAndDescription(
            cartItem.ordertype,
            cartItem.currentsubscriptionenddate
          );
        const enrichedCartItem = {
          ...cartItem,
          startDate,
          endDate,
          description,
        };

        setCartItems(enrichedCartItem);
        if (cartItem.subscriptionid === "151") {
          dispatch(
            updateSingleCart({
              cartsId: cartItem.cartsid,
              quantity: cartItem.quantity,
            })
          );
        } else {
          dispatch(
            updateMultiCart({
              cartsId: cartItem.cartsid,
              quantity: cartItem.quantity,
            })
          );
        }
      }
    } catch (error) {
      console.error("Failed to fetch cart:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteAction = async (cartsId, type) => {
    try {
      const response = await apiService.deleteCartItem(cartsId);

      if (response.success) {
        setCartItems(null);
        if (type === "single") {
          dispatch(
            updateSingleCart({
              cartsId: null,
              quantity: 0,
            })
          );
        } else if (type === "multiple") {
          dispatch(
            updateMultiCart({
              cartsId: null,
              quantity: 0,
            })
          );
        }
      }

      return response;
    } catch (error) {
      console.error("Failed to delete cart item:", error);
    }
  };
  const goToCheckoutPage = () => {
    if (!authID.u) {
      push({
        pathname: "/userConfirmation",
        state: { cartItems }
      });
    }

    if (
      authID.u &&
      authID.u.authenticationSource == "localAccountAuthentication"
    ) {
      push({
        pathname: "/checkout",
        state: { cartItems },
      });
    } else if (
      authID.u &&
      authID.u.authenticationSource == "socialIdpAuthentication"
    ) {
      alert(
        " openathen account is not suitable for checkout.Please login with your local account to proceed to checkout page"
      );
    }
  };

  const handleSubscriptionDatesAndDescription = (
    ordertype,
    currentsubscriptionenddate
  ) => {
    const baseText = "Therapeutic Guidelines 12 month subscription";
    const formatToMidnightUTC = (date) => {
      const d = new Date(date);
      d.setUTCHours(0, 0, 0, 0);
      return d.toISOString().replace(/\.000Z$/, "Z");
    };

    try {
      switch (ordertype) {
        case "New": {
          const today = new Date();
          return {
            description: `${baseText} (expires ${format(
              addYears(today, 1),
              "dd-MMM-yyyy"
            )})`,
            startDate: formatToMidnightUTC(today),
            endDate: formatToMidnightUTC(addYears(today, 1)),
          };
        }

        case "Renew": {
          const renewalStart = addDays(parseISO(currentsubscriptionenddate), 1);
          return {
            description: `${baseText} renewal (expires ${format(
              addYears(renewalStart, 1),
              "dd-MMM-yyyy"
            )})`,
            startDate: formatToMidnightUTC(renewalStart),
            endDate: formatToMidnightUTC(addYears(renewalStart, 1)),
          };
        }

        case "Addon":
          const endDate = parseISO(currentsubscriptionenddate);
          const startDate = addYears(endDate, -1); // Subtract 1 year from end date
          return {
            description: `${baseText} additional licenses (expires ${format(
              parseISO(currentsubscriptionenddate),
              "dd-MMM-yyyy"
            )})`,
            startDate: formatToMidnightUTC(startDate),
            endDate: formatToMidnightUTC(endDate),
          };

        default:
          return {
            description: baseText,
            startDate: null,
            endDate: null,
          };
      }
    } catch (error) {
      console.error("Error processing subscription:", error);
      return {
        description: baseText,
        startDate: null,
        endDate: null,
      };
    }
  };

  const calculateSubscriptionDates = (
    orderType,
    currentSubscriptionEndDate
  ) => {
    const formatToMidnightUTC = (date) => {
      const d = new Date(date);
      d.setUTCHours(0, 0, 0, 0);
      return d.toISOString().replace(/\.000Z$/, "Z");
    };

    const today = new Date();
    let startDate, endDate;

    switch (orderType) {
      case "New":
        startDate = formatToMidnightUTC(today);
        endDate = formatToMidnightUTC(
          new Date(today.setFullYear(today.getFullYear() + 1))
        );
        break;

      case "Addon":
        // For addon subscriptions, set both dates to null
        startDate = null;
        endDate = null;
        break;

      case "Renew":
        // For renewal, start date is day after current subscription ends
        const renewalStart = new Date(currentSubscriptionEndDate);
        renewalStart.setDate(renewalStart.getDate() + 1);
        startDate = formatToMidnightUTC(renewalStart);

        // End date is one year from renewal start
        const renewalEnd = new Date(renewalStart);
        renewalEnd.setFullYear(renewalEnd.getFullYear() + 1);
        endDate = formatToMidnightUTC(renewalEnd);
        break;

      default:
        startDate = formatToMidnightUTC(today);
        endDate = formatToMidnightUTC(
          new Date(today.setFullYear(today.getFullYear() + 1))
        );
    }

    return { startDate, endDate };
  };
  return (
    <Suspense fallback={<div className="loader"></div>}>
      <NavBar push={push} />
      <TopicBanner pathname={pathname} search={search} push={push} />

      {isLoading ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <p>Loading your cart items...</p>
        </div>
      ) : !cartItems ? (
        <div className="container myCart">
          <div className="col-lg-8 col-sm-8 col-xs-12 no_left_padding noRightPaddingxs empty-cart-message">
            No item found in your cart
          </div>
        </div>
      ) : (
        <div className="container myCart">
          <div className="col-lg-8 col-sm-8 col-xs-12 no_left_padding noRightPaddingxs">
            <h4 className="section-heading margintp-10px">My purchases</h4>

            <div id="responsive-tables">
              <table className="table table-bordered" id="myPurchases">
                <thead className="th_color">
                  <tr>
                    <th className="col-6 col-sm-12 text-center">Product</th>
                    <th className="col-2 col-md-1 col-sm-3 text-center">
                      Quantity
                    </th>
                    <th className="col-3 col-md-2 col-sm-3 text-center">
                      Total price (AUD, incl GST)
                    </th>
                    <th className="col-1 col-sm-3"></th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td
                      className="mycartitem col-6 col-sm-12"
                      data-title="Item"
                    >
                      {cartItems.description}
                    </td>
                    <td
                      className="Quantity col-2 col-md-1 col-sm-3"
                      data-title="Quantity"
                    >
                      {cartItems.quantity}
                    </td>
                    <td
                      className="mycartprice col-3 col-md-2 col-sm-3"
                      data-title="Price"
                    >
                      $
                      {cartItems.subscriptionid === "151"
                        ? cartItems.individualprice?.toFixed(2)
                        : cartItems.multipleprice?.toFixed(2)}
                    </td>
                    <td className="action col-1 col-sm-3">
                      <a
                        className="text_none"
                        href="#"
                        onClick={() =>
                          handleDeleteAction(
                            cartItems.cartsid,
                            cartItems.subscriptionid === "151"
                              ? "single"
                              : "multiple"
                          )
                        }
                      >
                        <i className="fa fa-trash-o font20px"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr className="footr_color">
                    <td colSpan="2" className="totalprice text-right">
                      Total :
                    </td>
                    <td colSpan="2" className="totalprice" id="finalprice">
                      {cartItems.subscriptionid === "151"
                        ? cartItems.individualprice?.toLocaleString("en-AU", {
                            style: "currency",
                            currency: "AUD",
                          })
                        : cartItems.multipleprice?.toLocaleString("en-AU", {
                            style: "currency",
                            currency: "AUD",
                          })}
                    </td>
                  </tr>
                </tfoot>
              </table>
              <p className="text-right">
                <a
                  href="/"
                  className="btn btn-sm btn-default"
                  title="Continue shopping"
                >
                  Continue shopping
                </a>
                <button className="btn btn-info" onClick={goToCheckoutPage}>
                  Proceed to checkout
                </button>
              </p>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </Suspense>
  );
};

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
});

export default connect(mapStateToProps, { push })(MyCart);
