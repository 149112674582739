import React, { useState, useEffect } from "react";
import VISA from "../../../assets/images/visa-payment.png";
import MasterCard from "../../../assets/images/mastercard-payment.png";
import Amex from "../../../assets/images/amex-payment.png";
import { paymentApi } from "../../../api/paymentApi";

const CardForm = ({ onPayment, amount, onError }) => {
  const [secureFieldCode, setSecureFieldCode] = useState("");
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    let script;
    let didCreateScript = false; // track if this component created the script
    let isMounted = true;        // track unmount state to avoid memory leaks

    paymentApi
      .getEwayPublicAPIKey()
      .then((publicApiKey) => {
        const existingScript = document.querySelector(
          'script[src="https://secure.ewaypayments.com/scripts/eWAY.min.js"]'
        );

        // If script does NOT exist, create it
        if (!existingScript) {
          script = document.createElement("script");
          script.src = "https://secure.ewaypayments.com/scripts/eWAY.min.js";
          script.type = "text/javascript";
          script.dataset.init = "false";
          script.onload = () => {
            if (!isMounted) return;
            initializeSecureFields(publicApiKey);
          };
          document.body.appendChild(script);
          didCreateScript = true;
        } else {
          // If script is in the DOM but window.eWAY is not yet available, 
          // wait for 'load' event
          if (!window.eWAY) {
            existingScript.addEventListener("load", () => {
              if (!isMounted) return;
              initializeSecureFields(publicApiKey);
            });
          } else {
            // Script and window.eWAY already loaded
            initializeSecureFields(publicApiKey);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching eWAY public API key:", error);
      });

    return () => {
      // Cleanup: remove script ONLY if we created it
      isMounted = false;
      if (didCreateScript && script) {
        document.body.removeChild(script);
      }
    };
  }, []);

  const initializeSecureFields = (publicApiKey) => {
    // Your styling/secureField setup code remains the same
    const commonStyles =
      "height: 40px; padding: 10px; border-radius: 5px; border: 1px solid #ccc;";

    const nameFieldConfig = {
      publicApiKey,
      fieldDivId: "eway-secure-field-name",
      fieldType: "name",
      styles: `${commonStyles} width: 100%;`,
    };
    const cardFieldConfig = {
      publicApiKey,
      fieldDivId: "eway-secure-field-card",
      fieldType: "card",
      styles: `${commonStyles} width: 100%;`,
    };
    const expiryFieldConfig = {
      publicApiKey,
      fieldDivId: "eway-secure-field-expiry",
      fieldType: "expiry",
      styles: `${commonStyles} width: 50%;`,
    };
    const cvnFieldConfig = {
      publicApiKey,
      fieldDivId: "eway-secure-field-cvn",
      fieldType: "cvn",
      styles: `${commonStyles} width: 50%;`,
    };

    const secureFieldCallback = (event) => {
      if (!event.fieldValid) {
        setError(event.errors);
      } else {
        setError(null);
      }
      if (event.secureFieldCode) {
        setSecureFieldCode(event.secureFieldCode);
      }
    };

    window.eWAY.setupSecureField(nameFieldConfig, secureFieldCallback);
    window.eWAY.setupSecureField(cardFieldConfig, secureFieldCallback);
    window.eWAY.setupSecureField(expiryFieldConfig, secureFieldCallback);
    window.eWAY.setupSecureField(cvnFieldConfig, secureFieldCallback);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    window.eWAY.saveAllFields(
      () => {
        if (secureFieldCode) {
          processPayment(secureFieldCode);
        } else {
          setError("Please fill in all credit card details correctly.");
          setIsSubmitting(false); 
        }
      },
      2000
    );
  };

  const processPayment = async (secureFieldCode) => {
    try {
      const paymentData = {
        Payment: { TotalAmount: amount },
        TransactionType: "Purchase",
        SecuredCardData: secureFieldCode,
      };

      const response = await paymentApi.processPayment(paymentData);

      if (response.TransactionStatus) {
        onPayment({
          paymentType: "cc",
          transactionId: response.TransactionID,
          status: "success",
          amount,
          cardType: response.CardType,
        });
      } else {
        onError({
          type: "credit Card",
          message: response.ResponseMessage || "eWAY Payment failed",
          code: response.responseCode,
        });
      }
    } catch (error) {
      console.error("Payment error:", error);
      const errorName = error?.response?.data?.name || "CAPTURE_ERROR";
      onError({
        type: "credit Card",
        code: errorName,
        message: "Failed to capture eWAY payment. Please try again later.",
      });
    }
  };

  return (
    <div className="card-form-cc mTop12px">
      <div className="payment-img-box">
        <img src={VISA} className="payment-img" alt="visa" />
        <img src={MasterCard} className="payment-img" alt="MasterCard" />
        <img src={Amex} className="payment-img" alt="amex" />
      </div>

      <form onSubmit={handleSubmit} className="cc-payment-form">
        <div
          className="secure-fields-container"
          style={{
            pointerEvents: isSubmitting ? "none" : "auto",
            opacity: isSubmitting ? 0.7 : 1,
          }}
        >
          <div className="form-group">
            <label htmlFor="eway-secure-field-name" className="form-label">
              Cardholder Name
            </label>
            <div id="eway-secure-field-name" className="cc-form-control" />
          </div>

          <div className="form-group">
            <label htmlFor="eway-secure-field-card" className="form-label">
              Card Number
            </label>
            <div id="eway-secure-field-card" className="cc-form-control" />
          </div>

          <div className="form-row">
            <div className="form-group" style={{ flex: 1, marginRight: "10px" }}>
              <label htmlFor="eway-secure-field-expiry" className="form-label">
                Expiration Date
              </label>
              <div id="eway-secure-field-expiry" className="cc-form-control" />
            </div>

            <div className="form-group" style={{ flex: 1 }}>
              <label htmlFor="eway-secure-field-cvn" className="form-label">
                CVV
              </label>
              <div id="eway-secure-field-cvn" className="cc-form-control" />
            </div>
          </div>
        </div>

        {error && <div className="alert alert-danger">{error}</div>}

        <button className="btn btn-primary" type="submit"  style={{
            display: "block",
            margin: "20px auto 0",
            width: "100%",
            maxWidth: "120px",
            height: "36px",
            fontSize: "20px",
            fontWeight: "bold",
            backgroundColor: isSubmitting ? "#ccc" : "rgb(26, 162, 160)",
            color: "#fff",
            border: "none",
            borderRadius: "6px",
            cursor: isSubmitting ? "not-allowed" : "pointer",
          }} disabled={isSubmitting}>
          {isSubmitting ? "Processing..." : "Pay"}
        </button>
      </form>
    </div>
  );
};

export default CardForm;
