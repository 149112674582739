import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { selectID } from "../../../features/counter/counterSlice";
import { useHistory } from "react-router-dom";
import { apiService } from "../../../api/dataverseApi";
import {
  countries,
  states,
  customerTypes,
  workTypeOptions,
  title,
} from "./billingData";
import InputField from "../../../components/InputField";
import SelectField from "../../../components/SelectField";

const BillingInformationForm = (props) => {
  const { nextSteps, contactDetails, setContactDetails } = props;
  const authID = useSelector(selectID);
  const history = useHistory();
  const handleGoBack = () => {
    history.push("/myCart");
  };

  const [formData, setFormData] = useState({
    customertypecode: "",
    address1_country: "",
    address1_stateorprovince: "",
    tg_title: "",
    firstname: "",
    lastname: "",
    address1_line1: "",
    address1_city: "",
    address1_postalcode: "",
    telephone1: "",
    tg_occupation: "",
    selectedWorkTypeText: "",
    company: "",
    tg_specify: "",
    contactid: "",
  });
  const [originalData, setOriginalData] = useState(null);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [phoneError, setPhoneError] = useState("");

  const isBusinessFieldRequired = formData.customertypecode === "4"; // organization
  
    useEffect(() => {
      let isMounted = true; 
      async function fetchUserProfile(uid) {
        try {
          const response = await apiService.getContact(uid);
  
          if (isMounted && Array.isArray(response) && response.length > 0) {
     //  console.log("response", response);
            const contactDetails = {
              customertypecode: response[0].customertypecode || "",
              company: response[0].company || "",
              tg_title: response[0].tg_title || "",
              firstname: authID.u?.given_name || response[0].firstname || "",
              lastname: authID.u?.family_name || response[0].lastname || "",
              address1_line1: response[0].address1_line1 || "",
              address1_city: response[0].address1_city || "",
              address1_stateorprovince: response[0].address1_stateorprovince || "",
              address1_postalcode: response[0].address1_postalcode || "",
              telephone1: response[0].telephone1 || "",
              address1_country: response[0].address1_country || "",
              tg_occupation: response[0].tg_occupation || "", 
              selectedWorkTypeText: occupationCodesReverse[response[0].tg_occupation] || "",
              tg_specify: response[0].tg_specify || "",
              contactid: response[0].contactid || "",
              nickname: response[0].nickname || "",
            };
            setOriginalData(contactDetails);
            setFormData(contactDetails);
            setContactDetails(contactDetails);
          }
  
          if (isMounted && Array.isArray(response) && response.length === 0) {
            console.log("this user has no contacts saved in the tables");
          }
        } catch (err) {
          console.error("Error fetching contact:", err);
        }
      }
  
      if (authID.u && authID.u["signInNames.emailAddress"]) {
        fetchUserProfile(authID.u["signInNames.emailAddress"]);
      
        
        if (authID.u.given_name && authID.u.family_name && isMounted) {
          setFormData((prevData) => ({
            ...prevData,
            firstname: authID.u.given_name,
            lastname: authID.u.family_name,
          }));
        }
      }
  
      return () => {
        isMounted = false;
      };
    }, [authID, setContactDetails]); 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "telephone1") {
      if (value.length > 10) {
        setPhoneError("Please enter a valid phone number (maximum of 10 digits)");
      } else {
        setPhoneError("");
      }
    }
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formData.telephone1.length > 10) {
      setPhoneError("Please enter a valid phone number (maximum of 10 digits)");
      return;
    }

    const data = {
      emailaddress1: authID?.u?.["signInNames.emailAddress"] || null,
      customertypecode: Number(formData.customertypecode),
      address1_country: formData.address1_country,
      address1_stateorprovince: formData.address1_stateorprovince,
      tg_title: Number(formData.tg_title),
      firstname: formData.firstname,
      lastname: formData.lastname,
      address1_line1: formData.address1_line1,
      address1_city: formData.address1_city,
      address1_postalcode: formData.address1_postalcode,
      telephone1: formData.telephone1,
      tg_occupation: Number(formData.tg_occupation),
      company: formData.company,
      tg_specify: formData.tg_specify,
      ...(formData.contactid !== "" && { contactid: formData.contactid }),
    };

    setSubmitStatus(true);
    if (authID?.u?.["signInNames.emailAddress"]){
      if (originalData) {
        const dataHasChanged = hasDataChanged(originalData, data);
      
        if (!dataHasChanged) {
          console.log("No changes detected, skipping API call...");
          nextSteps();
        }else{
          console.log("Changes detected, updating user profile...");
           try {
              const response = await apiService.updateContact(data);
              if (response.success) {
                console.log("User profile updated successfully");
                const finalContactId = response.newContactId || formData.contactid;
                setContactDetails({ ...data, nickname:contactDetails.nickname ||"", contactid: finalContactId });
                nextSteps();
              } else {
                alert("Billing Information updated failed");
              }
            } catch (err) {
              console.error("Error in updating user profile:", err.message);
            }
        }
      }
     
    }else{
      setContactDetails({ ...data }); //new user no need to save in backend at this stage
      nextSteps();
    }
    setSubmitStatus(false);
  };

  const workTypeOptionsWithValues = (customerTypeCode) => {
    const options = workTypeOptions[customerTypeCode] || [];
    return options.map(option => ({
      text: option,
      value: occupationCodes[option]
    }));
  };

 const occupationCodes = {
    "General Practitioner/General Practice": 1,
    "GP Training Organisations": 2,
    "GPRegistrars": 3,
    "Hospital Intern or Resident": 4,
    "Nurse": 5,
    "Pharmacist/Pharmacy": 6,
    "Specialist": 7,
    "Undergraduate": 8,
    "Other": 9,
    "Bookshops": 10,
    "Community Services": 11,
    "Government Departments": 12,
    "Nursing home/Aged Care": 13,
    "Other Organisation": 14,
    "Pharmaceutical Organisation": 15,
    "Universities": 16,
    "Private": 17,
    "Public": 18
  };

  const occupationCodesReverse = Object.fromEntries(
    Object.entries(occupationCodes).map(([key, value]) => [value, key])
  );

  const hasDataChanged = (originalData, currentData) => {
    const filterKeys = [
      "customertypecode",
      "address1_country",
      "address1_stateorprovince",
      "tg_title",
      "firstname",
      "lastname",
      "address1_line1",
      "address1_city",
      "address1_postalcode",
      "telephone1",
      "tg_occupation",
      "company",
      "tg_specify",
      "contactid",
    ];
  
    const cleanedOriginal = filterKeys.reduce((acc, key) => {
      acc[key] = originalData[key] ?? "";
      return acc;
    }, {});
  
    const cleanedCurrent = filterKeys.reduce((acc, key) => {
      acc[key] = currentData[key] ?? "";
      return acc;
    }, {});
    return JSON.stringify(cleanedOriginal) !== JSON.stringify(cleanedCurrent);
  };
  

  return (
    <form
      className="billing-information-form checkout-steps"
      onSubmit={handleSubmit}
    >
      <SelectField
        label="Customer type"
        id="customerType"
        name="customertypecode"
        value={formData.customertypecode}
        onChange={handleInputChange}
        options={customerTypes}
        required
      />

      {formData.customertypecode !== "2" && (
        <InputField
          label="Business Name"
          id="businessName"
          name="company"
          value={formData.company}
          onChange={handleInputChange}
          placeholder="Please enter your business name"
          required={isBusinessFieldRequired}
        />
      )}

      <SelectField
        label="Title"
        id="title"
        name="tg_title"
        value={formData.tg_title}
        onChange={handleInputChange}
        options={title}
        required
      />

      <InputField
        label="Given name"
        id="givenName"
        name="firstname"
        value={formData.firstname}
        onChange={handleInputChange}
        placeholder="Please enter your name"
        required
        disabled={authID.u && authID.u.given_name && authID.u.family_name}
      />

      <InputField
        label="Surname"
        id="surname"
        name="lastname"
        value={formData.lastname}
        onChange={handleInputChange}
        placeholder="Please enter your surname"
        required
        disabled={authID.u && authID.u.given_name && authID.u.family_name}
      />

      <div className="form-group mTop12px">
        <label
          htmlFor="address1_line1"
          className="col-lg-4 col-md-4 col-sm-5 control-label"
        >
          Street address:<span className="red">*</span>
        </label>
        <div className="col-lg-8 col-md-8 col-sm-7">
          <textarea
            name="address1_line1"
            id="address1_line1"
            className="form-control"
            placeholder="Please enter your street address"
            rows="2"
            value={formData.address1_line1}
            onChange={handleInputChange}
            required
          />
        </div>
      </div>

      <InputField
        label="Suburb"
        id="suburb"
        name="address1_city"
        value={formData.address1_city}
        onChange={handleInputChange}
        placeholder="Please enter your suburb"
        required
      />

      <SelectField
        label="State"
        id="states"
        name="address1_stateorprovince"
        value={formData.address1_stateorprovince}
        onChange={handleInputChange}
        options={states}
        required
      />

      <InputField
        label="Postcode"
        id="postalCode"
        name="address1_postalcode"
        value={formData.address1_postalcode}
        onChange={handleInputChange}
        placeholder="Please enter your postcode"
        type="number"
        required
      />

      <InputField
        label="Phone"
        id="telephone"
        name="telephone1"
        value={formData.telephone1}
        onChange={handleInputChange}
        placeholder="Please enter your phone number"
        type="tel"
        required
      />
      {phoneError && (
        <div className="col-lg-8 col-md-8 col-sm-7 col-lg-offset-4 col-md-offset-4 col-sm-offset-5">
         <div className="text-danger" style={{
          backgroundColor: '#FFF5F5',
          padding: '8px 12px',
          borderRadius: '4px',
          display: 'inline-block',
          border: '1px solid #FED7D7'
        }} >
       <span style={{marginLeft: '5px', fontWeight: 500, fontSize: '13px'}}>{phoneError}</span>
      </div>
        </div>
      )}
      <SelectField
        label="Country"
        id="country"
        name="address1_country"
        value={formData.address1_country}
        onChange={handleInputChange}
        options={countries}
        required
      />

      <SelectField
        label="Work Type"
        id="workType"
        name="tg_occupation"
        value={formData.tg_occupation || ""} 
        onChange={(e) => {
          const { value, options, selectedIndex } = e.target;
          setFormData(prev => ({
            ...prev,
            tg_occupation: value,
            selectedWorkTypeText: options[selectedIndex].text || options[selectedIndex].value
          }));
        }}
        options={workTypeOptionsWithValues(formData.customertypecode)}
        required
      />

      {formData.selectedWorkTypeText === "Other" && (
        <InputField
          label="Specify"
          id="specify"
          name="tg_specify"
          value={formData.tg_specify}
          onChange={handleInputChange}
          placeholder="Please enter your work type"
          required
        />
      )}

      <div className="mTop12px checkout-btn-box two-buttons">
        <button type="button" className="btn" onClick={handleGoBack}>
          Go back
        </button>
        <button type="submit" className="btn"  disabled={submitStatus}>
          {submitStatus ? "Submitting..." : "Next"}
        </button>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
});

export default connect(mapStateToProps)(BillingInformationForm);
