import { combineReducers } from "redux";
//actions
export const UPDATE_SINGLE_CART = "UPDATE_SINGLE_CART";
export const UPDATE_MULTI_CART = "UPDATE_MULTI_CART";

export const updateSingleCart = (cartData) => ({
  type: UPDATE_SINGLE_CART,
  payload: cartData,
});

export const updateMultiCart = (cartData) => ({
  type: UPDATE_MULTI_CART,
  payload: cartData,
});

const initialSingleCartState = {
  cartsId: null,
  quantity: 0,
};

const initialMultiCartState = {
  cartsId: null,
  quantity: 0,
};

export const getTotalCartQuantity = (state) => {
  return (
    state.subscripion.singleCart.quantity + state.subscripion.multiCart.quantity
  );
};

const singleCartReducer = (state = initialSingleCartState, action) => {
  switch (action.type) {
    case UPDATE_SINGLE_CART:
      return {
        ...state,
        cartsId: action.payload.cartsId,
        quantity: action.payload.quantity,
      };
    default:
      return state;
  }
};

const multiCartReducer = (state = initialMultiCartState, action) => {
  switch (action.type) {
    case UPDATE_MULTI_CART:
      return {
        ...state,
        cartsId: action.payload.cartsId,
        quantity: action.payload.quantity,
      };
    default:
      return state;
  }
};

const subscripionReducer = combineReducers({
  singleCart: singleCartReducer,
  multiCart: multiCartReducer,
});

export default subscripionReducer;
