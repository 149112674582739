/* eslint-disable */
import React, { useState, useEffect, Suspense } from "react";
import { push } from "connected-react-router";
import { connect, useSelector } from "react-redux";
import { selectID } from "../../features/counter/counterSlice";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { apiService } from "../../api/dataverseApi";
import MySubscriptions from "./mySubscriptions";
import ManageUsers from "./manageUsers";
import MyDetails from "./myDetails";

const NavBar = React.lazy(() => import("../../components/navBar"));
const TopicBanner = React.lazy(() => import("../../components/topicBanner"));
const Footer = React.lazy(() => import("../../components/footer"));

const UserProfile = (props) => {
  const { pathname, search, push } = props;
  const [profileData, setProfileData] = useState(null);
  const [activeTab, setActiveTab] = useState("details");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const authID = useSelector(selectID);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const getTabStyle = (tabName) => ({
    display: activeTab === tabName ? "block" : "none",
  });

  useEffect(() => {
    // todo add institution users
    const fetchUserProfile = async () => {
      try {
        if (!authID?.u?.["signInNames.emailAddress"]) {
          setError("User email not available");
          setLoading(false);
          return;
        }

        const email = authID.u["signInNames.emailAddress"];
        //console.log("Fetching profile for:", email);

        const response = await apiService.getUserProfile(email);
       // console.log("Profile response:", response);

        if (response.success) {
          setProfileData(response.data);
          setError(null);
        } else {
          setError(response.message || "Failed to fetch profile data");
        }
      } catch (err) {
        console.error("Profile fetch error:", err);
        setError(
          err.message || "An error occurred while fetching profile data"
        );
      } finally {
        setLoading(false);
      }
    };

    if (authID?.u) {
      fetchUserProfile();
    } else {
      setLoading(false);
    }
  }, [authID]);
  // }, []);

  return (
    <>
      <Suspense fallback={<div className="loader"></div>}>
        <NavBar push={push} />
        <TopicBanner pathname={pathname} search={search} push={push} />
        <MsalAuthenticationTemplate>
          {error && (
            <div className="error-container">
              <p className="error-message">{error}</p>
              <button
                onClick={() => window.location.reload()}
                className="retry-button"
              >
                Retry
              </button>
            </div>
          )}
          {loading && (
            <div className="loader-container">
              <div className="loader"></div>
              <p>Loading profile data...</p>
            </div>
          )}
          {!error && !loading && (
            <div className="container show-my-profile" id="wrap">
              <div className="col-lg-8 col-sm-8 col-xs-12 tab">
                <ul className="nav nav-tabs" role="tablist" id="myTab">
                  <li
                    role="presentation"
                    className={activeTab === "details" ? "active" : ""}
                    onClick={() => handleTabClick("details")}
                  >
                    <a>My details</a>
                  </li>

                  {(profileData?.subscriptions?.tg_subscriptionid === "168" ||
                    profileData?.subscriptions?.tg_subscriptionid === "151") &&
                    profileData?.subscriptions?.startdate !== "2100-01-01T00:00:01Z" &&
                    profileData?.subscriptions?.tg_expirydate !== "2100-01-01T00:00:01Z" && (
                    <li
                      role="presentation"
                      className={activeTab === "subscriptions" ? "active" : ""}
                      onClick={() => handleTabClick("subscriptions")}
                    >
                      <a>My subscriptions</a>
                    </li>
                  )}

                  {profileData?.subscriptions?.tg_subscriptionid === "168" && (
                    <li
                      role="presentation"
                      className={activeTab === "manageUsers" ? "active" : ""}
                      onClick={() => handleTabClick("manageUsers")}
                    >
                      <a>Manage users</a>
                    </li>
                  )}
                </ul>
              </div>

              <div className="tab-content">
                <div
                  className="tab-pane"
                  id="details"
                  style={getTabStyle("details")}
                >
                  <MyDetails contactData={profileData?.contact} push={push} />
                </div>
                <div
                  className="tab-pane"
                  id="subscriptions"
                  style={getTabStyle("subscriptions")}
                >
                  <MySubscriptions
                    contactId={profileData?.contact?.contactid}
                    isChild={!!profileData?.contact?._tg_parentcontactid_value}
                    subscriptions={profileData?.subscriptions || []}
                    push={push}
                  />
                </div>
                <div
                  className="tab-pane"
                  id="manageUsers"
                  style={getTabStyle("manageUsers")}
                >
                  <ManageUsers
                    subscriptions={profileData?.subscriptions || []}
                    mainAccountContact={profileData?.contact}
                  />
                </div>
              </div>
            </div>
          )}
        </MsalAuthenticationTemplate>
        <Footer />
      </Suspense>
    </>
  );
};

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
});

export default connect(mapStateToProps, { push })(UserProfile);
