import React, { useState } from "react";
import { updatePassword } from "../../api/B2CApi";

const PasswordChangeModal = ({ show, onClose, user, onSuccess }) => {
  const [passwordData, setPasswordData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [passwordError, setPasswordError] = useState("");
  const [showPasswordRequirements, setShowPasswordRequirements] = useState(false);

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    
    setPasswordData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSavePassword = async () => {
   // console.log("PasswordChangeModal.js   handleSavePassword called---", passwordData, user);
    try {
      setPasswordError("");
      if (!passwordData.newPassword || !passwordData.confirmPassword) {
        setPasswordError("Please fill in all password fields");
        return;
      }
      if (passwordData.newPassword !== passwordData.confirmPassword) {
        setPasswordError("Passwords do not match");
        return;
      }

      if (!user?.email) {
        setPasswordError("No valid user found");
        return;
      }

      const response = await updatePassword(user.email, passwordData.newPassword);
     // console.log("PasswordChangeModal.js   handleSavePassword response--", response);
      if (response.success) {
   
        if (onSuccess) {
          onSuccess(`User ${response.email} password updated successfully`);
        }
        handleClose();
      } else {
        setPasswordError("Failed to update password");
      }
    } catch (err) {
      setPasswordError(err.message || "An error occurred while updating password");
      console.error("Error updating password:", err);
    }
  };

  const handleClose = () => {
    setPasswordData({ newPassword: "", confirmPassword: "" });
    setPasswordError("");
    onClose();
  };

  return (
    <div
      className={`modal ${show ? "show" : ""} password-change-modal` }
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog modal-sm">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Change Password</h4>
            <button type="button" className="close" onClick={handleClose}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            {passwordError && (
              <div className="alert alert-danger" role="alert">
                {passwordError}
              </div>
            )}
            <div className="form-group mTop12px">
              <label className="control-label">New Password</label> 
              <span className="pwd-requirements-box">
              <i
                className="fa fa-info-circle ml-2"
                onMouseEnter={() => setShowPasswordRequirements(true)}
                onMouseLeave={() => setShowPasswordRequirements(false)}
                style={{ cursor: "help", marginLeft: "2px" }}
              ></i>
              {showPasswordRequirements && (
                <div className="pwd-requirements">
                  <strong>Password Requirements:</strong>
                  <ul>
                    <li>Must be between 8 and 256 characters</li>
                    <li>Must contain at least 3 of the following:</li>
                    <li>- Lowercase letters (a-z)</li>
                    <li>- Uppercase letters (A-Z)</li>
                    <li>- Numbers (0-9)</li>
                    <li>- Special characters (@ # $ % ^ &amp; * ...)</li>
                  </ul>
                </div>
              )}
              </span>
               
              <input
                type="password"
                name="newPassword"
                className="form-control"
                placeholder="Please enter new password"
                value={passwordData.newPassword}
                onChange={handlePasswordChange}
                required
              />
             
            </div>
            <div className="form-group mTop12px">
              <label className="control-label">Confirm Password</label>
              <input
                type="password"
                name="confirmPassword"
                className="form-control"
                placeholder="Please confirm new password"
                value={passwordData.confirmPassword}
                onChange={handlePasswordChange}
                required
              />
            </div>
          </div>
          <div className="modal-footer">
            <p className="text-center" style={{ marginBottom: 0 }}>
              <button
                type="button"
                className="btn btn-info borderNone"
                onClick={handleClose}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-info borderNone"
                onClick={handleSavePassword}
              >
                Save changes
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordChangeModal;
