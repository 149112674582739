import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchToken,
  fetchData,
  CacheManager,
  fetchFileFromZip,
} from "./counterAPI";
import { getUrlObj, removeSpecialCharacters } from "../../utils";
import { apiService } from "../../api/dataverseApi"; 
// import JSZip from 'jszip';
// import { cleanDITA } from './dita-clean';

import { UpdateURL } from "./updateURL";

import $ from "jquery";
import { rxhtmltag4jQuery } from "./rxhtmltag";
rxhtmltag4jQuery();

export const QueryString = () => {
  let queryString = {};
  let querySearch = [];
  try {
    if (window.location.hash !== "") {
      // manage searchFixed topics wmg1-co etc...
      // console.log(window.location.hash);
      window.location.hash = window.location.hash.replace(
        /\#\w*-\w*-c_/g,
        "c_"
      );
      // console.log(window.location.hash);
    }

    querySearch = window.parent.location.search.replace("?", "").split("&");
    $(querySearch).each((index, element) => {
      queryString[element.split("=")[0]] = element.split("=")[1];
    });
    // console.log(28,"Q>>>",JSON.stringify(queryString));
  } catch (error) {
    // console.log("no querystring",error);
  }
  return queryString;
};
const ccmsversion =
  localStorage.getItem("ccmsversion") !== null
    ? localStorage.getItem("ccmsversion")
    : process.env.REACT_APP_CCMS_VERSION;

    let authIDu = null;
    const storedAuthIDu = localStorage.getItem("authIDu");
    
    if (storedAuthIDu !== null) {
      authIDu = JSON.parse(storedAuthIDu);
      
      if (authIDu.exp < Date.now() / 1000) {
        authIDu = null;
        localStorage.removeItem("authIDu");
      }
    }

const initialState = {
  value: [], // fielPath
  openSection: [],
  status: "idle",
  auth: 0,
  id:
    process.env.REACT_APP_B2C_DISABLE_AUTH === "true"
      ? { u: " ", i: " " }
      : { u: authIDu, i: null },
  map: "",
  zip: null,
  ditaPath: "",
  XRefPath: "",
  uxPlatform: "desktop",
  queryString: QueryString(),
  defaultPath: "",
  changeGuideline: 0,
  fetchqueue: [],
  C5State: {
    platform: "tg.org.au",
    databaseID: "Antibiotic",
    siteID: "singlemulti",
    titleID: "bartenella-infections",
    itemID: "references",
    itemVersion: "1",
    publisherId: "tgl:TGL",
    proprietaryId: "tgl:Antibiotic",
    itemPubDate: "references",
    accessMethod: "Regular",
    eventType: "Request" /*Investigation,Searches_Regular */,
    sectionType: "Section",
    dataType: "Database",
    itemDataType: "Other",
    id: "",
  },
  ip: "0.0.0.0",
  medium: "",
  referenceFiles: {
    pbs_links: "preprocess/drug_key_links_trim.dita",
    source_experts: "Contributors/source_experts.dita",
    drug_keydef: "preprocess/drug_keydef_trim.ditamap",
    refContent: { file: "", active: 0 },
  },
  cache: {
    chapter: {
      title: "",
      children: 0,
      content: "<div>Processing...</div>",
      level: 6,
    },
  }, //filepath:{title:"title",content:"Dita"} //activeTopic:{title:"",content:""}
  version: {
    selected: ccmsversion,
    items: [
      { label: "Master", value: "main" },
      { label: "v0.20", value: "s1" },
      { label: "v1.0", value: "s2" },
      { label: "v3.0", value: "s3" },
      { label: "v5.1", value: "s4" },
      { label: "s5", value: "s5" },
      { label: "s6", value: "s6" },
      { label: "s7", value: "s7" },
      { label: "s8", value: "s8" },
      { label: "s9", value: "s9" },
    ],
  },
  iDB: new CacheManager({ name: ccmsversion }),
  mpstopicfile: {
    Addiction_Medicine: {
      text: "Addiction",
      topicfile: "alcohol-drug-problems",
      mpsguidelineid: 34,
      bannerclass: "book-addiction",
      topiccode: "amg1-c",
      mobheader: "rgb(18, 72, 81)",
    },
    Antibiotic: {
      text: "Antibiotic",
      topicfile: "bartonella-infections",
      mpsguidelineid: 3,
      bannerclass: "book-antibiotic",
      topiccode: "abg16-c",
      mobheader: "rgb(247, 174, 173)",
    },
    Bone_and_Metabolism: {
      text: "Bone and Metabolism",
      topicfile: "thyroid-disorders-overview",
      mpsguidelineid: 28,
      bannerclass: "book-bone",
      topiccode: "bmg2-c",
      mobheader: "rgb(188, 191, 0)",
    },
    Cardiovascular: {
      text: "Cardiovascular",
      topicfile: "cardiovascular-disease-risk-stratification",
      mpsguidelineid: 4,
      bannerclass: "book-cardiovascular",
      topiccode: "cvg7-c",
      mobheader: "rgb(155, 125, 186)",
    },
    Dermatology: {
      text: "Dermatology",
      topicfile: "acne",
      mpsguidelineid: 5,
      bannerclass: "book-dermatology",
      topiccode: "dmg-c",
      mobheader: "rgb(149, 135, 113)",
    },
    Developmental_Disability: {
      text: "Developmental Disability",
      topicfile: "cerebral-palsy",
      mpsguidelineid: 33,
      bannerclass: "book-developmental",
      topiccode: "ddg4-c",
      mobheader: "rgb(63, 196, 183)",
    },
    Diabetes: {
      text: "Diabetes",
      topicfile: "other-types-of-diabetes",
      mpsguidelineid: 26,
      bannerclass: "book-diabetes",
      topiccode: "dbg1-c",
      mobheader: "rgb(255, 244, 82)",
    },
    Fatigue: {
      text: "Fatigue",
      topicfile: "fatigue-introduction",
      mpsguidelineid: 17,
      bannerclass: "book-fatigue",
      topiccode: "ftg-c",
      mobheader: "rgb(66, 65, 67)",
    },
    Gastrointestinal: {
      text: "Gastrointestinal",
      topicfile: "disorders-oesophagus",
      mpsguidelineid: 7,
      bannerclass: "book-gastrointestinal",
      topiccode: "gig-c",
      mobheader: "rgb(0, 84, 159)",
    },
    Liver_Disorders: {
      text: "Liver Disorders",
      topicfile: "abnormal-liver-biochemistry",
      mpsguidelineid: 32,
      bannerclass: "book-liver",
      topiccode: "ldg1-c",
      mobheader: "rgb(70, 10, 104)",
    },
    Neurology: {
      text: "Neurology",
      topicfile: "acute-management-of-seizures-and-status-epilepticus",
      mpsguidelineid: 8,
      bannerclass: "book-neurology",
      topiccode: "nrg5-c",
      mobheader: "rgb(236, 9, 40)",
    },
    Oral_and_Dental: {
      text: "Oral and Dental",
      topicfile: "dental-caries",
      mpsguidelineid: 9,
      bannerclass: "book-oral",
      topiccode: "dtg3-c",
      mobheader: "rgb(139, 131, 215)",
    },
    Other: {
      text: "Other",
      topicfile: "anaphylaxis",
      mpsguidelineid: 24,
      bannerclass: "book-other",
      topiccode: "oth-c",
      mobheader: "rgb(221, 222, 223)",
    },
    Pain_and_Analgesia: {
      text: "Pain and Analgesia",
      topicfile: "understanding-pain",
      mpsguidelineid: 2,
      bannerclass: "book-pain",
      topiccode: "agg7-c",
      mobheader: "rgb(0, 164, 229)",
    },
    Palliative_Care: {
      text: "Palliative Care",
      topicfile: "palliative-care-overview",
      mpsguidelineid: 10,
      bannerclass: "book-palliative",
      topiccode: "pcg4-c",
      mobheader: "rgb(186, 224, 202)",
    },
    Psychotropic: {
      text: "Psychotropic",
      topicfile: "delirium",
      mpsguidelineid: 11,
      bannerclass: "book-psychotropic",
      topiccode: "ptg8-c",
      mobheader: "rgb(0, 162, 97)",
    },
    Respiratory: {
      text: "Respiratory",
      topicfile: "asthma-introduction-and-diagnosis",
      mpsguidelineid: 19,
      bannerclass: "book-respiratory",
      topiccode: "rsg6-c",
      mobheader: "rgb(253, 191, 87)",
    },
    Rheumatology: {
      text: "Rheumatology",
      topicfile: "gout",
      mpsguidelineid: 12,
      bannerclass: "book-rheumatology",
      topiccode: "rhg3-c",
      mobheader: "rgb(242, 105, 34)",
    },
    Sexual_and_Reproductive_Health: {
      text: "Sexual and Reproductive Health",
      topicfile: "sterilisation",
      mpsguidelineid: 27,
      bannerclass: "book-sexual",
      topiccode: "srg2-c",
      mobheader: "rgb(147, 55, 87)",
    },
    Toxicology_and_Toxinology: {
      text: "Toxicology and Toxinology",
      topicfile: "what-is-covered-in-toxicology-toxinology",
      mpsguidelineid: 30,
      bannerclass: "book-toxicology",
      topiccode: "ttg1-c",
      mobheader: "rgb(65, 83, 99)",
    },
    Ulcer_and_Wound_Management: {
      text: "Ulcer and Wound Management",
      topicfile: "fundamentals-of-ulcer-and-wound-management",
      mpsguidelineid: 14,
      bannerclass: "book-ulcer",
      topiccode: "lwg2-c",
      mobheader: "rgb(137, 212, 223)",
    },
    Wilderness_Medicine: {
      text: "Wilderness Medicine",
      topicfile: "altitude-illness",
      mpsguidelineid: 31,
      bannerclass: "book-wilderness",
      topiccode: "wmg1-c",
      mobheader: "rgb(188, 189, 192)",
    },
    quicklinks: {
      text: "Drug use in pregnancy and breastfeeding",
      topicfile: "altitude-illness",
      mpsguidelineid: 31,
      bannerclass: "book-wilderness",
      topiccode: "wmg1-c",
      mobheader: "rgb(188, 189, 192)",
    },
  },
  displayBanner: false,
  headTitle: "",
  tocId: "",
  hashRedirect: false,
  versionByImport: false,
  subscriptionStatus: {
    canPurchase: true,
    hasAppliedCoupon: false,
    status: "idle",  // "idle" | "loading" | "succeeded" | "failed"
    error: null
  },
};
// const iDB = new CacheManager({name: initialState.version.selected});

//Important - change MPS guidelineID for live...
/* 
  TTG: Live=29,Beta=30
  WMG: Live=30,Beta=31
  LDG: Live=31,Beta=32
  DDG: Live=32,Beta=33
  AMG: Live=33,Beta=34
*/

export const OpenTOCbyURL = (action, state) => {
  let targetTopic = undefined;

  let mapState = $("<body />").html(state.map)

  let queryString = "";
  try {
    queryString = initialState.queryString;
  } catch (error) {
    console.log(error);
  }
  // console.log("querystring",queryString);
  let checklocalStorage;
  // let checklocalStorage = localStorage.getItem("xrefPath");

  const uxPlatform = state.uxPlatform;

  setTimeout(() => {
    // console.log(action.payload.dartSelectors);
    let urlObj = getUrlObj();
    targetTopic = (urlObj.pathname + urlObj.search + urlObj.hash).replace("#startdart", "");
    let urlExactMatch = false;
    let foundHash = false;
    // console.log(targetTopic);
    if (targetTopic.indexOf("/access/") > -1) {
      // new url
      targetTopic = '[tocpath="' + targetTopic + '"]';
      // console.log(targetTopic);
    } else if (uxPlatform === "mobile") {
      // MPS Mobile
      // use below querystring for testing.
      //bookId=32&bookTitle=Liver%20Disorders&topicId=21231&topicTitle=Abnormal%20liver%20biochemistry&topicFileName=abnormal-liver-biochemistry.html&topicSectionId=&topicDivId=

      targetTopic = queryString.topicFileName.replace(".html", "");
      targetTopic =
        'topicref [mpspath*="topicfile=' +
        targetTopic +
        '"]:not([href*=".ditamap"])';
      // console.log(128,"counterslide.js-mobile-mapState",$(mapState).html());
      // console.log(129,"counterslide.js-mobile-targetTopic",targetTopic);

      let testTargetTopic = $(mapState).find(targetTopic).length;
      if (testTargetTopic === 0) {
        targetTopic = targetTopic.replace(
          "topicref [mpspath*",
          "topicref[mpspath*"
        );
      }

      targetTopic = $(mapState)
        .find(targetTopic)
        .not('topicref[mpspath*="guidelinename=auto"]')
        .first()
        .attr("href");

      // console.log(130, "counterSlice.js", JSON.stringify(queryString));

      try {
        if (queryString.topicSectionId.length > 0) {
          checklocalStorage = queryString.topicSectionId;
          // console.log(146,"counterSlice-checklocalStoragelength",checklocalStorage.length);
          // console.log(152,"counterSlice-sectionid",checklocalStorage);
        }
      } catch (error) {}

      try {
        if (queryString.topicDivId.length > 0) {
          // eg - reader. https://tglstatic.z8.web.core.windows.net/dart/dev/main.js?v=6-31-18: Line 1 : 71 counter.js http://localhost/reader?bookId=-1&bookTitle=Gastrointestinal&topicId=-1&topicTitle=Gastric%20disorders&topicFileName=gastric-disorders.html&topicSectionId=gig-c02-s5&topicDivId=gig-c02-s5%7C2
          checklocalStorage = queryString.topicDivId.replace("%7C", "|");
          // console.log(35, "counterSlice-checkocalStorage", checklocalStorage);
        }
      } catch (error) {}

      // console.log(138, "counterslide.js-mobile-target", targetTopic, checklocalStorage);
    } else if (uxPlatform === "webpage") {
      let targetTopic = queryString.topicFileName.replace(".html", "");
      targetTopic = `topicref [mpspath*="topicfile=${targetTopic}"]:not([href*=".ditamap"])`;
      
      let testTargetTopic = $(mapState).find(targetTopic).length;
      
      if (testTargetTopic === 0) {
        targetTopic = targetTopic.replace("topicref [mpspath*", "topicref[mpspath*");
      }
      
      targetTopic = $(mapState)
        .find(targetTopic)
        .not('topicref[mpspath*="guidelinename=auto"]')
        .first()
        .attr("href");

    } else {
      // mps urls
      // if(targetTopic.indexOf("/viewTopic?topicfile")>-1){}
      // targetTopic=window.location.search.replace("?","");
      // console.log(targetTopic);

      targetTopic =
        '[mpspath*="' + targetTopic + '"]:not([href*=".ditamap"]):eq(0)';
      // console.log(targetTopic);

      if (
        $(mapState).find(targetTopic.replace("mpspath*=", "mpspath="))
          .length === 1
      ) {
        // find exact match
        targetTopic = targetTopic.replace("mpspath*=", "mpspath=");
        // console.log("exact match found");
        urlExactMatch = true;
      } else if (
        $(mapState).find(
          targetTopic
            .replace("mpspath*=", "mpspath=")
            .replace("/viewTopic/?", "/viewTopic?")
        ).length === 1
      ) {
        // find exact match
        targetTopic = targetTopic
          .replace("mpspath*=", "mpspath=")
          .replace("/viewTopic/?", "/viewTopic?");
        // console.log("exact match found");
        urlExactMatch = true;
      } else if (
        window.parent.location.hash !== "" &&
        window.parent.location.hash.indexOf("|") === -1
      ) {
        // e.g. /viewTopic?topicfile=hepatitis-c#ldg1-c07-s8-3
        let hashFile = window.parent.location.hash.replace("#", "");
        let mappedHref = $(mapState)
          .find("#" + hashFile)
          .closest("[href]")
          .attr("href");
        if (mappedHref !== undefined) {
          targetTopic = '[href="' + mappedHref + '"]';
          foundHash = true;
          // console.log(targetTopic);
        } else {
          $(mapState)
            .find(
              '[mpspath*="' +
                window.parent.location.hash.replace("#", "") +
                '"]'
            )
            .each((index, element) => {
              let currentMPSPath = $(element).attr("mpspath");
              // console.log(currentMPSPath,queryString.topicfile);
              if (currentMPSPath.indexOf(queryString.topicfile) > -1) {
                targetTopic = '[mpspath="' + currentMPSPath + '"]:eq(0)';
                foundHash = true;
                return false;
              }
            });

          // console.log(foundHash);
          if (foundHash === false) {
            let findSection = $(mapState)
              .find('[mpspath*="sectionId=' + hashFile + "#" + hashFile + '"]')
              .attr("href");

            if (findSection !== undefined) {
              targetTopic = '[href="' + findSection + '"]';
              foundHash = true;
              // console.log("false-foundHash-section",targetTopic);
            } else {
              targetTopic =
                '[mpspath*="topicfile=' + queryString.topicfile + '"]:eq(0)';
              // console.log("false-foundHash-else",targetTopic);
            }
          }

          if (foundHash === false) {
            targetTopic = '[href*="' + hashFile + '"]';
            let findSection = $(mapState).find(targetTopic).attr("href");
            if (findSection !== undefined) {
              foundHash = true;
              targetTopic = '[href="' + findSection + '"]';
            }
          }
        }
        // console.log(mappedHref);
      } else if (
        window.parent.location.search.indexOf("navigateTopic") > -1 ||
        window.parent.location.search.indexOf("?topicfile") > -1
      ) {
        // direct to topics e.g. viewTopic?topicfile=liver-lesions&guidelineName=Liver%20Disorders&topicNavigation=navigateTopic
        targetTopic =
          'topicref[mpspath*="topicfile=' + queryString.topicfile + '"]';
        // console.log(targetTopic);

        if (window.parent.location.hash !== "") {
          if (window.parent.location.hash.indexOf("|") > -1) {
            let newTargetTopic = window.location.hash
              .replace("#", "")
              .split("|")[0];

            let regexPattern = /^c_\w*_/g; // applies to c_WMG_
            let regexTest = regexPattern.test(newTargetTopic);
            // console.log(regexTest,newTargetTopic);
            if (regexTest === true) {
              targetTopic = 'topicref[mpspath*="' + newTargetTopic + '"]';
              foundHash = true;
            }

            if (window.parent.location.search.indexOf("navigateTopic") > -1) {
              // console.log(queryString.navigateTopic);
              if (
                queryString.navigateTopic &&
                queryString.navigateTopic.indexOf(".dita") > -1
              ) {
                let navigateFromQuery = decodeURIComponent(
                  queryString.navigateTopic
                );
                navigateFromQuery =
                  navigateFromQuery.split(".dita")[0] + ".dita";
                targetTopic = '[href*="' + navigateFromQuery + '"]';
                foundHash = true;
              } else {
                const params = new URLSearchParams(window.location.search);
                const navigateTopic = params.get("navigateTopic");
                if (navigateTopic.indexOf(".dita") > -1) {
                  let navigateFromQuery = decodeURIComponent(navigateTopic);
                  navigateFromQuery =
                    navigateFromQuery.split(".dita")[0] + ".dita";
                  targetTopic = '[href*="' + navigateFromQuery + '"]';
                  foundHash = true;
                }
              }
              $(mapState)
                .find(targetTopic)
                .each((index, element) => {
                  let targetTopicTag = $(element).prop("tagName");
                  if (
                    targetTopicTag === "CHAPTER" ||
                    targetTopicTag === "MAPREF"
                  ) {
                    targetTopic = $(element)
                      .find("topicref:eq(0)")
                      .attr("href");
                    targetTopic = '[href="' + targetTopic + '"]';
                    urlExactMatch = true;
                  }
                });
            }

            // console.log(targetTopic);
          }
        } else if (window.parent.location.href.indexOf("?topicfile=c_") > -1) {
          // applies to word(docx) imported dita content
          targetTopic = 'chapter[mpspath*="' + queryString.topicfile + '"]';
          // console.log(targetTopic);
        }
      } else if (
        window.parent.location.href.indexOf("#") > -1 &&
        window.parent.location.href.indexOf("|") > -1 &&
        window.parent.location.href.indexOf(".ditamap") > -1
      ) {
        // console.log("tryHREF....");
        const hashPath = window.parent.location.href
          .split("#")[1]
          .split("|")[0];
        targetTopic = `[href="${hashPath}.dita"]`;
        // console.log(targetTopic);
        const checkTargetTopic = $(mapState).find(targetTopic).attr("href");
        // console.log(checkTargetTopic);
        if (checkTargetTopic !== undefined) {
          foundHash = true;
        }
      }
    }

    if (uxPlatform !== "mobile")
      if (urlExactMatch === true || foundHash === true) {
        targetTopic = $(mapState).find(targetTopic).attr("href");
        // console.log(targetTopic,"foundHash:",foundHash,"|urlexactMatch:",urlExactMatch);
      } else {
        targetTopic = $(mapState)
          .find(targetTopic)
          .not('topicref[mpspath*="guidelinename=auto"]')
          .first()
          .attr("href");
      }

    // console.log(targetTopic);

    if (uxPlatform !== "mobile")
      if (targetTopic === undefined) {
        targetTopic =
          'topicref[mpspath*="topicfile=' + queryString.topicfile + '"]:eq(0)';
        // console.log("targetTopic",targetTopic);
        targetTopic = $(mapState).find(targetTopic).attr("href");
      }
    // console.log("topicFileHREF",targetTopic);

    let sendDartSelectors = action.payload.request
      ? action.payload.request.dartSelectors //fromFetchMap
      : action.payload.dartSelectors; // from iDBMap

    if (targetTopic !== undefined) {
      let targetTopicTag = $(mapState)
        .find('[href="' + [targetTopic] + '"]')
        .prop("tagName");
      // console.log(targetTopicTag);
      if (uxPlatform !== "mobile")
        if (targetTopicTag === "CHAPTER") {
          targetTopic = $(mapState)
            .find('[href="' + [targetTopic] + '"]')
            .find("topicref:eq(0)")
            .attr("href");
        }

      if (uxPlatform !== "mobile")
        if (checklocalStorage) {
          localStorage.removeItem("xrefPath");
          targetTopic = checklocalStorage.split("#")[0];
          checklocalStorage = checklocalStorage.split("#")[1];
        } else {
          checklocalStorage = "";
        }
      UpdateURL(targetTopic, sendDartSelectors, checklocalStorage);

      if (sendDartSelectors.changeGuideline === 1) {
        localStorage.setItem("xrefPath", sendDartSelectors.XRefPath);
      }
    } else if (sendDartSelectors.changeGuideline === 1) {
      localStorage.setItem("xrefPath", sendDartSelectors.XRefPath);
      if (uxPlatform !== "mobile") window.parent.location.reload();
    } else if (window.parent.location.href.indexOf("topicfile=c_") > -1) {
      targetTopic =
        'topicref[href="' +
        window.parent.location.href.split("topicfile=")[1] +
        '.dita"]';
      // console.log(targetTopic);
      targetTopic = $(mapState).find(targetTopic).attr("href");
      if (targetTopic !== undefined) {
        UpdateURL(targetTopic, sendDartSelectors, checklocalStorage);
      }
    }
  }, 1000);
};

const NewTopicStyles = (stateMap, hrefSelectedTopic) => {
  // console.log("hrefSelectedTopic",hrefSelectedTopic);
  $(stateMap)
    .find(
      "topicref.active, chapter.active, ditatitle.active, section.active, mapref.active"
    )
    .removeClass("active");
  $(stateMap).find(hrefSelectedTopic).addClass("active");
  $(stateMap)
    .find(hrefSelectedTopic)
    .find("> map > topicref")
    .addClass("active");
  $(stateMap)
    .find(hrefSelectedTopic)
    .parents("bookmap")
    .find("ditatitle:eq(0)")
    .addClass("active");
  $(stateMap)
    .find(hrefSelectedTopic)
    .parents("topicref, chapter")
    .addClass("active");
  $(stateMap)
    .find(hrefSelectedTopic)
    .find("> topic > section")
    .addClass("active");
};

const _getCacheContent = (state, action) => {
  try {
    let findLinkTag = "topicref, chapter";

    if ($(action.payload.contentMap).html().indexOf("<mapref") !== 0)
      if ($(action.payload.contentMap).find("mapref").length > 0)
        findLinkTag = "> mapref";

    
    state.value = [];
    

    $(action.payload.contentMap)
      .find(findLinkTag)
      .not(".tandf")
      .each((index, element) => {
        const topicFile = $(element).attr("href");
        let children = parseInt($(element).attr("children")) || 0;
        let level = parseInt($(element).attr("level")) || 0;
        // console.log(index,element.tagName,topicFile);
        if (
          element.parentElement.tagName === "TOPICHEAD" ||
          element.parentElement.parentElement.tagName === "MAP" ||
          element.parentElement.parentElement.tagName === "BOOKMAP"
        ) {
          // for references, indeces and to make map work.
          children = 1;
        }
        if (
          element.parentElement.tagName === "CHAPTER" ||
          element.parentElement.parentElement.tagName === "MAP"
        ) {
          // for references, indeces and to make map work.
          level = 2;
        }

        // console.log(element.parentElement.parentElement.tagName,children);
        const topicTitle = $(element).find("ditatitle:eq(0)").text();
        // console.log(topicTitle);
        let cachedContent = "";
        let loadTopic = topicFile
          .replace(".ditamap", "")
          .replace(".dita", "")
          .replace(/_/g, " ")
          .replace(/\//g, " ");
        let processingText =
          '<span class="dottext">Processing </span><div class="dot"></div><span class="dottext">' +
          loadTopic +
          "</span>";
        let logInButton =
          '<button class="btn btn-default" onclick="document.querySelector(\'#logInbtn\').click();">Log in or subscribe to view contents.</button>';
        
        const authID = { ...state.id };
        cachedContent = authID.u || authID.i 
        ? state.cache[topicFile]?.content : logInButton;
                
        if (
          cachedContent === undefined ||
          cachedContent === processingText ||
          cachedContent === logInButton
        ) {
          cachedContent = processingText;
          const authID = { ...state.id };
          if (!authID.u && !authID.i) {
            cachedContent = logInButton;
          }
          state.cache = {
            ...state.cache,
            [topicFile]: {
              title: topicTitle,
              children,
              level,
              content: cachedContent,
            },
          };
          action.payload.dartSelectors.iDB.read(topicFile).then((value) => {
            if (authID.u || authID.i) {
              if (
                topicFile.indexOf(".dita") > -1 ||
                topicFile.indexOf(".txt") > -1
              )
                action.payload.dartSelectors.dispatch(
                  fetchContent({
                    guideline: action.payload.dartSelectors.ditapath,
                    topicFile,
                    dartSelectors: action.payload.dartSelectors,
                  })
                );
            } else if (value !== null) {
              action.payload.dartSelectors.dispatch(
                iDBContent({ topicFile, value: logInButton })
              );
            }
          });
        }

        if (findLinkTag !== "> mapref" && state.value.indexOf(topicFile) === -1)
          state.value.push(topicFile);
      });

    //CLEAN Cache to improve performance.
    let retainCachedTopic = [
      "chapter",
      state.referenceFiles.pbs_links,
      state.referenceFiles.drug_keydef,
      state.referenceFiles.source_experts,
    ]; // TODO: see state to update as needed.
    for (let cachedTopic in state.cache) {
      if (retainCachedTopic.indexOf(cachedTopic) > -1) {
        // console.log("Retain",cachedTopic);
      } else if (state.value.indexOf(cachedTopic) > -1) {
        // console.log("Cache",cachedTopic);
      } else {
        // console.log("Delete", cachedTopic);
        delete state.cache[cachedTopic];
      }
    }
    // console.log(state.cache);
  } catch (err) {}
};

const processCacheContent = (tag, stateMap, state, action) => {
  // console.log(contentTag,action.payload.selectedTopic);
  let contentMap = $(stateMap)
    .find('[href="' + action.payload.selectedTopic + '"]:eq(0)')
    .closest("map")
    .find("topicref:eq(0)")
    .parent();
  if (tag === "TOPICHEAD") {
    contentMap = $(stateMap)
      .find('[href="' + action.payload.selectedTopic + '"]:eq(0)')
      .closest("map")
      .find("topicref:eq(0)")
      .parent()
      .parent();
  }

  // console.log($(closestMap).html());
  if ($(contentMap).html() === undefined) {
    contentMap = $(stateMap)
      .find('[href="' + action.payload.selectedTopic + '"]:eq(0)')
      .closest("bookmap")
      .find("topicref:eq(0)")
      .parent();
    if (tag !== "CHAPTER") {
      contentMap = $(stateMap)
        .find('[href="' + action.payload.selectedTopic + '"]:eq(0)')
        .closest("bookmap")
        .find("topicref:eq(0)")
        .parent()
        .parent();
    }
  }

  //TODO: fix why content section does not update for some parents
  if (tag === "BOOKMAP" || tag === "CHAPTER") {
    contentMap = $(stateMap)
      .find('[href="' + action.payload.selectedTopic + '"]:eq(0)')
      .parent()
      .parent(); // requires parent...keep

    if ($(contentMap).find("bookmeta").length > 0) {
      // when topicref is in the root toc and there is just 1 topic.
      contentMap = $(stateMap)
        .find('[href="' + action.payload.selectedTopic + '"]:eq(0)')
        .wrap("<p>")
        .parent()
        .html();
      $(stateMap)
        .find('[href="' + action.payload.selectedTopic + '"]:eq(0)')
        .unwrap();
      contentMap = contentMap + contentMap; // dupliated because index=0 is hidden in contentsection.
      contentMap = $("<map />").html(contentMap);
      $(contentMap).find("topicref").attr("children", "1").wrap("<p>");
      // console.log($(closestMap).html());
    }
  }

  _getCacheContent(state, {
    payload: { contentMap, dartSelectors: action.payload.dartSelectors },
  });
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getAuthToken = createAsyncThunk(
  "counter/fetchToken",
  async (request) => {
    const response = await fetchToken({ dartSelectors: request.dartSelectors });
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const fetchMap = createAsyncThunk(
  "counter/fetchMap",
  async (request) => {
    const response = await fetchData({
      guideline: "",
      topicfile: request.path,
      dartSelectors: request.dartSelectors,
    });
    request.dartSelectors.iDB.write(request.path, response.data);
    // The value we return becomes the `fulfilled` action payload
    //response.data = cleanDITA(response.data);
    //console.log("===CLEANED",response.data);
    let combinedResponse = { data: response.data, request };

    return combinedResponse;
  }
);

export const fetchContentNow = async (dartSelectors, guideline, topicFile) => {
  let response = "";
  if (dartSelectors.zip !== null && dartSelectors.zip !== 1) {
    response = await fetchFileFromZip(guideline + topicFile, dartSelectors.zip);
    dartSelectors.iDB.write(guideline + topicFile, response.data);
  } else {
    response = await fetchData({
      guideline: guideline,
      topicfile: topicFile,
      dartSelectors: dartSelectors,
    });
    dartSelectors.iDB.write(topicFile, response.data);
  }
  return response.data;
};

export const fetchContent = createAsyncThunk(
  "counter/fetchContent",
  async (request) => {
    let response = "";
    const iDBContent = await request.dartSelectors.iDB.read(request.topicFile);
    if (iDBContent) {
      return { data: iDBContent, request };
    } 

    if (request.dartSelectors.zip !== null && request.dartSelectors.zip !== 1 && 
      !(
        request.guideline === "quicklinks/" &&
        request.topicFile === "patient_information_pdfs.dita"
      )
    ) {
      // null is initial state. 1 is downloading. final state is an array
      // console.log("===>",request.guideline,request.topicFile);
      response = await fetchFileFromZip(
        request.guideline + request.topicFile,
        request.dartSelectors.zip
      );
      // console.log(response);
      request.dartSelectors.iDB.write(
        request.guideline + request.topicFile,
        response.data
      );
    } else {
      response = await fetchData({
        guideline: request.guideline,
        topicfile: request.topicFile,
        dartSelectors: request.dartSelectors,
      });
      request.dartSelectors.iDB.write(request.topicFile, response.data);
    }

    // The value we return becomes the `fulfilled` action payload
    //response.data = cleanDITA(response.data);
    //console.log("===CLEANED",response.data);
    let combinedResponse = { data: response.data, request };

    return combinedResponse;
  }
);

export const fetchSubscriptionStatusThunk = createAsyncThunk(
  "counter/fetchSubscriptionStatus",
  async (email, { rejectWithValue }) => {
    //console.log("running createAsyncThunk", email);
    try {
      const response = await apiService.getUserProfile(email);
      if (response.success && response.data?.subscriptions) {
        const { tg_expirydate, tg_couponreferrercode } = response.data.subscriptions;
        const endDate = new Date(tg_expirydate);
        const threeMonthsFromEnd = new Date(tg_expirydate);
        threeMonthsFromEnd.setMonth(threeMonthsFromEnd.getMonth() + 3);

        const today = new Date();
        const hasAppliedCoupon = !!tg_couponreferrercode;
//console.log("today",today,"endDate",endDate,"threeMonthsFromEnd",threeMonthsFromEnd);
        if (today < threeMonthsFromEnd) {
          return { 
            canPurchase: false,
            hasAppliedCoupon 
          };
        }
        // Otherwise user can purchase again
        return { canPurchase: true, hasAppliedCoupon };
      }
      // If no subscription info
      return { canPurchase: true, hasAppliedCoupon: false };
    } catch (error) {
      console.error("Failed to check subscription status:", error);
      // Return some default values if the API call failed
      return rejectWithValue({ 
        canPurchase: true, 
        hasAppliedCoupon: false 
      });
    }
  }
);

const _updateOpenSection = (state, action) => {
  try {
    if (action.payload.tag !== "H2" && !state.openSection.includes(action.payload.selectedTopic)) {
      state.openSection.push(action.payload.selectedTopic);

      state.C5State.databaseID = state.ditaPath.replace("/", "");
      const cleanDitapath = removeSpecialCharacters(state.ditaPath);
      state.C5State.siteID = "single-multi";
      state.C5State.titleID = action.payload.selectedTopic
        .replace(".ditamap", "")
        .replace(".dita", "");
      state.C5State.itemID = action.payload.selectedTopic
        .replace(".ditamap", "")
        .replace(".dita", "");
      state.C5State.itemVersion =
        state.mpstopicfile[cleanDitapath].mpsguidelineid; //TODO: update to version
      state.C5State.proprietaryId = "tgl:" + state.C5State.databaseID;
      state.C5State.itemPubDate = $("#contentId .reviseddate").text().trim();
      state.C5State.id = JSON.stringify(state.id);

      // console.log("ADDING> instead of toggle");
      return; // exit and avoid running the below.
    }

    let checkExistingPath = state.openSection.filter((element) => {
      return element === action.payload.selectedTopic;
    });
    // console.log("exists?>",checkExistingPath);
    if (checkExistingPath.length === 0) {
      // add only if path does not exist.
      state.openSection.push(action.payload.selectedTopic);
    } else if (action.payload.tag) {
      let deletedOpenSection = state.openSection.filter((element) => {
        return element !== action.payload.selectedTopic;
      });
      state.openSection = deletedOpenSection;
    }
  } catch (err) {
    console.log(err);
  }
};

export const counterSlice = createSlice({
  name: "counter",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    iDBContent: (state, action) => {
      // console.log(action.payload.topicFile,action.payload.value);
      try {
        state.cache[action.payload.topicFile].content = action.payload.value;
      } catch (error) {
        // console.log(error);
        let contentKey = {
          [action.payload.key]: {
            title: action.payload.key,
            children: 0,
            content: action.payload.value,
          },
        };
        state.cache = { ...state.cache, ...contentKey };
      }
    },
    iDBMap: (state, action) => {
      state.map = action.payload.value;
      // console.log(action);
      OpenTOCbyURL(action, state);
    },
    updateTOC: (state, action) => {
      state.referenceFiles.refContent.active = 0; // always deactivate modal if toc is updated.
      let stateMap = $("<span>").html(state.map);
      let hrefSelectedTopic =
        '[href="' + action.payload.selectedTopic + '"]:eq(0)';

      state.XRefPath =
        action.payload.tag === "H2" ? "" : action.payload.XRefPath; // make xrefPath blank to prevent scroll on H2 click
      // console.log(hrefSelectedTopic);

      let contentMap = $(stateMap).find(hrefSelectedTopic);
      let contentMapParent = $(stateMap).find(hrefSelectedTopic).parent();

      if ($(contentMap).attr("children") === 0) {
        contentMap = $(stateMap)
          .find(hrefSelectedTopic)
          .closest('[children!="0"]');
        contentMapParent = $(stateMap)
          .find(hrefSelectedTopic)
          .closest('[children!="0"]')
          .parent();
      }
      // console.log("children",$(contentMap).attr("children"));
      //if(parseInt($(contentMap).attr("children"))===0)
      let contentTag = $(contentMapParent).prop("tagName");
      // console.log(contentTag);
      _updateOpenSection(state, action);

      if ($(stateMap).find(hrefSelectedTopic).hasClass("active")) {
        //simply toggle view
        // if(0){ //simply toggle view
        console.log("=====updateTOC", "simple toggle", hrefSelectedTopic);
        // console.log("simpletoggle>",hrefSelectedTopic,action.payload.dartSelectors);
        // updateOpenSection(state,{payload:{dartSelectors: action.payload.dartSelectors,filePath:hrefSelectedTopic}});
        // $(stateMap).find(hrefSelectedTopic).addClass("active");

        $(stateMap).find(hrefSelectedTopic).toggleClass("active");
        $(stateMap)
          .find(hrefSelectedTopic)
          .find("> map > topicref")
          .toggleClass("active");
        // console.log(hrefSelectedTopic);
        // console.log($(stateMap).find(hrefSelectedTopic).prop("tagName"));
        if ($(stateMap).find(hrefSelectedTopic).prop("tagName") === "CHAPTER")
          $(stateMap)
            .find(hrefSelectedTopic)
            .closest("bookmap")
            .find("ditatitle")
            .toggleClass("active");
        // $(stateMap).find(hrefSelectedTopic).parents("bookmap:eq(0)").find("ditatitle:eq(0)").toggleClass("active");
        // $(stateMap).find(hrefSelectedTopic).parents("topicref:eq(0), chapter:eq(0)").toggleClass("active");
      } else if (
        parseInt(
          $(stateMap)
            .find(hrefSelectedTopic + ":eq(0)")
            .attr("children")
        ) === 0 &&
        hrefSelectedTopic.indexOf(".dita#") === -1
      ) {
        //No children and not tnf, get file
        console.log(
          "=====updateTOC",
          "No children, get file",
          hrefSelectedTopic
        );
        $(".slider-arrow.hide").trigger("click");
        // console.log(JSON.stringify(state.value));
        // console.log(">>",state.value[0]);
        if (state.value.length === 0 || state.value[0].indexOf("tandf") !== 0) {
          // console.log("=======NoChildren...YES dispatch");
          NewTopicStyles(stateMap, hrefSelectedTopic);
          // console.log("DispatchCacheContent called from no children, get file");
          processCacheContent(contentTag, stateMap, state, action);
        } else {
          console.log("=======NoChildren...NO dispatch", hrefSelectedTopic);
          $(stateMap)
            .find('topicref[children="0"].active')
            .removeClass("active");
          $(stateMap).find(hrefSelectedTopic).addClass("active");
          $(stateMap)
            .find(hrefSelectedTopic)
            .parents("topicref, chapter")
            .addClass("active");
        }

        // $(currentTopicRef).addClass("active");
      } else {
        // New Topic, get files
        console.log(
          "=====updateTOC",
          "// New Topic, get files",
          action.payload.selectedTopic
        );
        // state.openSection=[]; // clear openSection since this is a new topic.
        NewTopicStyles(stateMap, hrefSelectedTopic);

        // console.log(contentTag);
        processCacheContent(contentTag, stateMap, state, action);
      }
      //console.log($(stateMap).html());
      state.map = $(stateMap).html();
    },
    // change Guideline
    changeGuideline: (state, action) => {
      state.changeGuideline = 1;
      let extractDitaPath =
        action.payload.xrefHref.replace("../../", "").split("/")[0] + "/";
      state.ditaPath = extractDitaPath;
      state.defaultPath = action.payload.xrefHref;
      state.XRefPath = action.payload.xrefHref.replace(
        "../../" + extractDitaPath,
        ""
      );
      state.value = [];
      state.openSection = [];

      // if(uxPlatform!=="mobile") {
      //   if(1){//(window.parent.location.href.indexOf("tgldcdp.tg.org.au")>-1){ // removed to fix dxp; required for mps
      //     $("body").css("opacity","0"); // to stop the flicker/jumpiness...
      //   }else{
      //   }
      // }

      // $("#contentId").html('<span class="dottext">Processing </span><div class="dot"></div><span class="dot"></span>');
      // $('body,html').animate({scrollTop:0}, 500, "swing");
    },
    setGuideline: (state, action) => {
      let search = action.payload.search;
      let searchParams = new URLSearchParams(search);
      let ditaPath = $("#tg_toc_id").attr("ditapath") || "";
      let uxPlatform = $("#tg_toc_id").attr("platform") || "web"; // e.g. mobile
      try {
        if (searchParams.get('bookTitle')) {
          //uxPlatform = "mobile" does not work because the tag is not planted yet.
          ditaPath = searchParams.get('bookTitle').replaceAll(' ', "_") + "/";
          uxPlatform = "mobile";
          $(".etgtopic .bodyContentDiv").css("display", "none");
        } else if (searchParams.get('guidelinePage')) {
          ditaPath = searchParams.get('guidelinePage').replaceAll(' ', "_") + "/";
        } else if (searchParams.get('sectionId')) {
          const guideline_abbrv_map = {
            'amg': 'Addiction Medicine', 
            'abg': 'Antibiotic',
            'bmg': 'Bone and Metabolism',
            'cvg': 'Cardiovascular',
            'dmg': 'Dermatology',
            'ddg': 'Developmental Disability',
            'dbg': 'Diabetes',
            'ftg': 'Fatigue',
            'gig': 'Gastrointestinal',
            'ldg': 'Liver Disorders',
            'nrg': 'Neurology',
            'dtg': 'Oral and Dental',
            'oth': 'Other',
            'agg': 'Pain and Analgesia',
            'pcg': 'Palliative Care',
            'ptg': 'Psychotropic',
            'rsg': 'Respiratory',
            'rhg': 'Rheumatology',
            'srg': 'Sexual and Reproductive Health',
            'ttg': 'Toxicology and Toxinology',
            'lwg': 'Ulcer and Wound',
            'wmg': 'Wilderness Medicine',
          }
          const sectionId = searchParams.get('sectionId');
          const guideline_abbrv = sectionId[2] + sectionId[3] + sectionId[4];
          ditaPath = guideline_abbrv_map[guideline_abbrv.toLocaleLowerCase()].replaceAll(' ', "_") + "/";

        } else {
          let topics = window.tgg.topics;
          let guidelines = window.tgg.guidelines;
          let topicFile = searchParams.get('topicfile').replace("_1", "").toLowerCase().replace('-', '_');
          for (let guideline in topics) {
            for (let key in topics[guideline]) {
              if (key.toLowerCase().includes(topicFile)) {
                ditaPath = guideline;
                break;
              }
            }
          }
          if (ditaPath === "") {
            for (let guideline in guidelines) {
              for (let key in guidelines[guideline]) {
                if (key.toLowerCase().replace('-', '_').includes(topicFile)) {
                  ditaPath = guideline;
                  break;
                }
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
      if (!ditaPath.endsWith("/")) {
        ditaPath += "/";
      }
      state.ditaPath = ditaPath;
    },
    resetDefaultPath: (state, action) => {
      state.defaultPath = "";
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateReference: (state, action) => {
      state.referenceFiles.refContent.file = action.payload.drugKey;
      state.referenceFiles.refContent.active = action.payload.active;
    },
    resetXRefPath: (state, action) => {
      state.XRefPath = "";
    },
    updateOpenSection: (state, action) => {
      _updateOpenSection(state, action);
    },
    updateUser: (state, action) => {
      let authIDu = action.payload;
      authIDu = { ...authIDu, exp: Math.floor(Date.now() / 1000) + (365 * 24 * 60 * 60) / 2 };
      localStorage.setItem("authIDu", JSON.stringify(authIDu));
      state.id.u = action.payload;
      state.C5State = {...state.C5State, id: JSON.stringify(state.id)};
    },
    updateInstitution: (state, action) => {
      state.id.i = action.payload;
      state.C5State = {...state.C5State, id: JSON.stringify(state.id)};

    },
    updateState: (state, action) => {
      // console.log("action",action.payload);
      const { payload } = action;

      for (let key in payload) {
        if (key === "openSection") {
          state["openSection"] = [...state["openSection"], payload[key]];
        } else {
          state[key] = payload[key];
        }
      }
    },
    emptyOpenSection: (state) => {
      state["openSection"] = [];
    },
    UpdateVersionByImport: (state, action) => {
      state.versionByImport = action.payload;
    },
    logoutUser: (state) => {
      state.id.u = null;
    },
    resetSubscriptionStatus: (state) => {
      state.subscriptionStatus = {
        canPurchase: true,
        hasAppliedCoupon: false,
        status: "idle",
        error: null
      }; // this is for user logout and not logged in.
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getAuthToken.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAuthToken.fulfilled, (state, action) => {
        state.status = "idle";
        state.auth = action.payload;
      })
      .addCase(fetchMap.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMap.fulfilled, (state, action) => {
        state.status = "idle";

        state.map = action.payload.data;
        // if(state.map===''){
        // }
        // console.log(action.payload);
        // iDB.write(action.payload.request.path,action.payload.data)

        // console.log(window.parent.location.search);

        OpenTOCbyURL(action, state);
      })
      .addCase(fetchContent.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchContent.fulfilled, (state, action) => {
        state.status = "idle";
        // console.log(action);
        // console.log(state);

        if (action.payload === undefined) {
          return;
        } else {
          // console.log("=>",action);
        }
        // console.log("=>",action);

        let title = "";
        let content = "";

        if (action.payload.request.refContentFile === 1) {
          // console.log(action.payload.request.topicFile);
          state.referenceFiles.refContent.file =
            action.payload.request.topicFile;
          state.referenceFiles.refContent.active = 1;
          let glossaryData = $("<body />").html(action.payload.data);
          glossaryData = $(glossaryData).find("glossterm").text();
          title = glossaryData;
        } else {
          title = action.payload.request.topicFile;
        }
        content = action.payload.data;

        try {
          state.cache[action.payload.request.topicFile].content =
            action.payload.data;
        } catch (error) {
          let topicObject = action.payload.request.topicFile;
          let contentKey = { [topicObject]: { title, children: 0, content } };
          state.cache = { ...state.cache, ...contentKey };
          // console.log(topicObject, error);
        }
        // iDB.write(action.payload.request.topicFile,action.payload.data);
        //state.value = action.payload.data;
      })
      .addCase(fetchSubscriptionStatusThunk.pending, (state) => {
        state.subscriptionStatus.status = "loading";
        state.subscriptionStatus.error = null;
      })
      
      .addCase(fetchSubscriptionStatusThunk.fulfilled, (state, action) => {
        state.subscriptionStatus.canPurchase = action.payload.canPurchase;
        state.subscriptionStatus.hasAppliedCoupon = action.payload.hasAppliedCoupon;
        state.subscriptionStatus.status = "succeeded";
        state.subscriptionStatus.error = null;
      })
      
      .addCase(fetchSubscriptionStatusThunk.rejected, (state, action) => {
        state.subscriptionStatus.status = "failed";
        state.subscriptionStatus.error = action.error?.message;
      });
  },
});

export const {
  updateTOC,
  updateReference,
  resetXRefPath,
  updateOpenSection,
  changeGuideline,
  resetDefaultPath,
  iDBContent,
  iDBMap,
  updateState,
  updateUser,
  updateInstitution,
  emptyOpenSection,
  clearAndAddNewValue,
  UpdateVersionByImport,
  logoutUser,
  resetSubscriptionStatus,
  setGuideline
} = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAuth = (state) => state.counter.auth;
export const selectMap = (state) => state.counter.map;
export const selectValue = (state) => state.counter.value;
export const selectCache = (state) => state.counter.cache;
export const selectVersion = (state) => state.counter.version;
export const selectMPSTopicFile = (state) => state.counter.mpstopicfile;
export const selectDitaPath = (state) => state.counter.ditaPath;
export const selectXRefPath = (state) => state.counter.XRefPath;
export const selectOpenSection = (state) => state.counter.openSection;
export const selectChangeGuideline = (state) => state.counter.changeGuideline;
export const selectC5State = (state) => state.counter.C5State;
export const selectIP = (state) => state.counter.ip;
export const selectUXPlatform = (state) => state.counter.uxPlatform;
export const selectUQueryString = (state) => state.counter.queryString;
export const selectDefaultPath = (state) => state.counter.defaultPath;
export const selectFetchQueue = (state) => state.counter.fetchqueue;
export const selectReferenceFiles = (state) => state.counter.referenceFiles;
export const selectDisplayBanner = (state) => state.counter.displayBanner;
export const selectMedium = (state) => state.counter.medium;
export const selectHeadTitle = (state) => state.counter.headTitle;
export const selectTocId = (state) => state.counter.tocId;
export const selectHashRedirect = (state) => state.counter.hashRedirect;
export const selectID = (state) => state.counter.id;
export const selectZip = (state) => state.counter.zip;
export const selectiDB = (state) => state.counter.iDB;
export const selectVersionByImport = (state) => state.counter.versionByImport;
export const selectSubscriptionStatus = (state) => state.counter.subscriptionStatus;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const incrementIfOdd = (amount) => (dispatch, getState) => {
  // const currentValue = selectCount(getState());
  // if (currentValue % 2 === 1) {
  //   dispatch(incrementByAmount(amount));
  // }
};

export default counterSlice.reducer;
