/* eslint-disable */
import React, { useState, useEffect, Suspense } from "react";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { apiService } from "../../api/dataverseApi";

const MySubscriptions = (props) => {
  const { pathname, search, push, subscriptions, contactId ,isChild } = props;
  const [selectedUsers, setSelectedUsers] = useState("");
  const [isAddOnActive, setIsAddOnActive] = useState(false);

  function generateToken(length = 32) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let token = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      token += characters[randomIndex];
    }
    return token;
  }
  useEffect(() => {
    const checkSubscriptionStatus = () => {
      if (!subscriptions?.tg_expirydate) return false;
      const endDate = new Date(subscriptions.tg_expirydate);
      const today = new Date();
      return endDate > today;
    };
    setIsAddOnActive(checkSubscriptionStatus());
  }, [subscriptions]);

  const handleRenewalClick = async () => {
    let token = localStorage.getItem("tgCartToken");
    if (!token) {
      token = generateToken();
      localStorage.setItem("tgCartToken", token);
    }

    const renewalItem = {
      token,
      subscriptionid: subscriptions.tg_subscriptionid.toString(),
      quantity: subscriptions.tg_numberoflicences || 1, 
      ordertype: "Renew",
      currentSubscriptionId: subscriptions.entitlementid,
      enddate: subscriptions.tg_expirydate,
      hasappliedcoupon: subscriptions.tg_couponreferrercode !== null
    };

    try {
      const response = await apiService.addToCart(renewalItem);
      if (response.success) {
        setTimeout(() => push("/myCart"), 2000);
      } else {
        throw new Error(response.message || "Failed to add renewal to cart");
      }
    } catch (error) {
      console.error("Failed to add renewal to cart:", error);
      alert(
        error.message || "An error occurred while adding the renewal to cart."
      );
    }
  };

  const handleAddLicense = async () => {
   // console.log(" subscriptions.tg_couponreferrercode",  subscriptions.tg_couponreferrercode, subscriptions.tg_couponreferrercode !== null);
    if (!selectedUsers) {
      alert("Please select the number of users to add");
      return;
    }

    let token = localStorage.getItem("tgCartToken");
    if (!token) {
      token = generateToken();
      localStorage.setItem("tgCartToken", token);
    }

    const addLicenseItem = {
      token,
      subscriptionid: subscriptions.tg_subscriptionid.toString(),
      quantity: parseInt(selectedUsers),
      ordertype: "Addon",
      enddate: subscriptions.tg_expirydate,
      startdate: subscriptions.startdate,
      currentSubscriptionId: subscriptions.entitlementid,
      currentmultilicensenumber: subscriptions.tg_numberoflicences,
      hasappliedcoupon: subscriptions.tg_couponreferrercode !== null,
      contactid: contactId
    };

    try {
      const response = await apiService.addToCart(addLicenseItem);
      if (response.success) {
        setTimeout(() => push("/myCart"), 2000);
      } else {
        throw new Error(response.message || "Failed to add licenses to cart");
      }
    } catch (error) {
      console.error("Failed to add licenses to cart:", error);
      alert(
        error.message || "An error occurred while adding the licenses to cart."
      );
    }
  };

  const handleTGOnlineClick = () => {
    window.location.replace("https://app.tg.org.au/");
  };

  const getAvailableLicenseOptions = () => {
    if (!subscriptions || subscriptions.tg_subscriptionid !== '168') return [];

    const maxLicenses = 5;
    const currentLicenses = subscriptions.tg_numberoflicences;
    const availableLicenses = maxLicenses - currentLicenses;
    return Array.from({ length: availableLicenses }, (_, i) => i + 1);
  };

  const isRenewalPeriod = () => {
    if (!subscriptions?.tg_expirydate) return false;
    if (subscriptions.tg_numberoflicences > 5) return false;
    const endDate = new Date(subscriptions.tg_expirydate);
    const today = new Date();

    // Calculate dates 4 months before and after expiry
    const fourMonthsBefore = new Date(endDate);
    fourMonthsBefore.setMonth(endDate.getMonth() - 4);

    const threeMonthsAfter = new Date(endDate);
    threeMonthsAfter.setMonth(endDate.getMonth() + 3);
    //console.log("isRenewalPeriod;", fourMonthsBefore, threeMonthsAfter);
    return today >= fourMonthsBefore && today <= threeMonthsAfter;
  };

  const formatExpiryDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-AU", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  return (
    <>
      <Suspense fallback={<div className="loader"></div>}>
        {subscriptions && (
          <div className="col-lg-12 noLeftPadding noRightPadding">
            <h3 className="tab-section-heading">Therapeutic Guidelines</h3>
            <div className="col-lg-12 subscriptionBox">
              <div className="col-sm-6 noLeftPadding noRightPadding item-center">
                <p>
                  <strong>Therapeutic Guidelines</strong> &nbsp;expiry&nbsp;
                  <strong>
                    <span id="dat0">
                      {formatExpiryDate(subscriptions.tg_expirydate)}
                    </span>
                  </strong>
                  <br />
                </p>
              </div>

              <div className="col-sm-6 text-right noRightPadding">
                <button
                  type="button"
                  className="btn btn-info btn-sm"
                  onClick={handleTGOnlineClick}
                >
                  Therapeutic Guidelines online
                </button>
                {!isChild && <button
                  type="button"
                  className="btn btn-info btn-sm  "
                  disabled={!isRenewalPeriod()}
                  onClick={handleRenewalClick}
                >
                  Renew
                </button>
                }         
                 {!isChild && (
                 <div 
                        className="text-right" 
                        style={{ backgroundColor: "#d9edf7",
                          borderRadius: "0.5rem",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                          margin: "1rem 0 0",
                          fontWeight: "500",
                          lineHeight: "1.5",
                          textAlign: "right",
                         }}
                      >
                        If you want to purchase/renew more than 5 licenses, contact TGL at sales@tg.org.au 
                        or call us on +61 3 9329 1566
                      </div>
                     )} 
              </div>
                
            </div>
             
            {subscriptions.tg_subscriptionid === "168" &&
              subscriptions.tg_numberoflicences < 5 &&
              isAddOnActive && (
                <>
                  <h4 className="tab-section-heading mTop20px">
                    Purchase additional licence
                  </h4>

                  <div className="row form-horizontal">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="col-lg-8 col-sm-8 col-xs-8 marginxs control-label">
                          Number of users to add:
                        </label>
                        <div className="col-lg-4 col-xs-4 active">
                          <select
                            id="usersToAdd"
                            name="usersToAdd"
                            className="form-control reducedPadding"
                            onChange={(e) => setSelectedUsers(e.target.value)}
                          >
                            <option value="">please select</option>

                            {getAvailableLicenseOptions().map((num) => (
                              <option key={num} value={num}>
                                {num}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="col-lg-12 col-sm-8 col-xs-8 marginxs control-label">
                    <h5>
                      Additional licences can be purchased at any time. Licence
                      price will be calculated on a pro-rata basis with a single
                      expiry date for all subscriptions on an account.
                    </h5>
                  </div>

                  <div className="text-right col-lg-12 mTop12px">
                    <input
                      type="button"
                      value="Add licence"
                      className="btn btn-info btn-sm borderNone"
                      onClick={handleAddLicense}
                    />
                  </div>
                </>
              )}
          </div>
        )}
      </Suspense>
    </>
  );
};

export default MySubscriptions;
