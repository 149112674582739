/* eslint-disable */
import React, { useState, useEffect, Suspense } from "react";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import axios from "axios";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { apiService } from "../../../api/dataverseApi";

const NavBar = React.lazy(() => import("../../../components/navBar"));
const TopicBanner = React.lazy(() => import("../../../components/topicBanner"));
const Footer = React.lazy(() => import("../../../components/footer"));

const XeroAuth = (props) => {
  const { pathname, search, push } = props;
  const [accountInfo, setAccountInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const connectXero = async () => {
    const clientId = process.env.REACT_APP_XERO_CLIENT_ID;
    const redirectUrl = process.env.REACT_APP_XERO_REDIRECT_URL;
 const xeroAuthUrl = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUrl)}&scope=openid profile email accounting.transactions accounting.settings accounting.contacts offline_access`;
//const xeroAuthUrl = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=34E18857FBA142C2AD6847215F1FC315&redirect_uri=https://localhost:3000/xeroAuth/response&scope=openid profile email accounting.transactions accounting.settings accounting.contacts offline_access`;

   window.location.href = xeroAuthUrl;
  };

  const getXeroAccounts = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.getXeroAccounts();
        console.log(" xero auth Response:", response);

  
      setAccountInfo(response);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const renderAccountInfo = (account, title) => {
    if (!account) return (
      <div className="alert alert-warning" style={{ marginTop: '10px', marginBottom: '10px' }}>
        {title} account not found. Please ensure account with correct code is set up in Xero.
      </div>
    );

    return (
      <div className="panel panel-default" style={{ marginBottom: '15px' }}>
        <div className="panel-heading">
          <h3 className="panel-title">{title}</h3>
        </div>
        <div className="panel-body">
          <dl className="dl-horizontal">
            <dt>ID:</dt>
            <dd>{account.id}</dd>
            <dt>Code:</dt>
            <dd>{account.code}</dd>
            <dt>Name:</dt>
            <dd>{account.name}</dd>
          </dl>
        </div>
      </div>
    );
  };


  return (
      <Suspense fallback={<div className="loader"></div>}>
        <NavBar push={push} />
        <TopicBanner pathname={pathname} search={search} push={push} />
        <MsalAuthenticationTemplate>
        <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <h3>Xero Integration Setup</h3>
                
                {/* Connect to Xero Section */}
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h3 className="panel-title">Step 1: Connect to Xero</h3>
                  </div>
                  <div className="panel-body">
                    <p>Connect your Xero account to get started.</p>
                    <button className="btn btn-primary" onClick={connectXero}>
                      Connect to Xero
                    </button>
                  </div>
                </div>

                {/* Get Account Information Section */}
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h3 className="panel-title">Step 2: Verify Account Setup</h3>
                  </div>
                  <div className="panel-body">
                    <p>Retrieve and verify your Xero account information.</p>
                    <button 
                      className="btn btn-primary" 
                      onClick={getXeroAccounts}
                      disabled={loading}
                    >
                      {loading ? 'Loading...' : 'Get Account Information'}
                    </button>

                    {error && (
                      <div className="alert alert-danger" style={{ marginTop: '15px' }}>
                        <strong>Error:</strong> {error}
                      </div>
                    )}

                    {accountInfo && (
                      <div style={{ marginTop: '20px' }}>
                        <h4>Account Information:</h4>
                        <div className="alert alert-info">
                          <strong>Tenant ID:</strong> {accountInfo.tenantId}
                        </div>
                        
                        {/* {renderAccountInfo(accountInfo.paypalAccount, "PayPal")}
                        {renderAccountInfo(accountInfo.ewayAccount, "eWay")} */}
                        {/* {renderAccountInfo(accountInfo.revenueAccount, "Revenue")}
                        {renderAccountInfo(accountInfo.revenueAccountRenewal, "Revenue(renewal)")} */}

                        {accountInfo.message && (
                          <div className="alert alert-success">
                            {accountInfo.message}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MsalAuthenticationTemplate>
        <Footer />
      </Suspense>
  );
};

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
});

export default connect(mapStateToProps, { push })(XeroAuth);
