/* eslint-disable */

import React, { Suspense, useEffect, useState } from "react";
import { connect, useDispatch,useSelector } from "react-redux";
import { push } from "connected-react-router";
import { updateState, selectID,selectSubscriptionStatus ,resetSubscriptionStatus, fetchSubscriptionStatusThunk,} from "../../features/counter/counterSlice";
import { HashLink } from 'react-router-hash-link';
import { selectSearchIndex, setSearchIndex, setSearchTerm } from '../../store/reducers/search';
import { useHistory } from 'react-router-dom';
import Purchase from "../../features/subscription/purchase";
import useScreenSize from '../../hooks/useScreenSize';
import { apiService } from "../../api/dataverseApi";

const NavBar = React.lazy(() => import('../../components/navBar'));
const Footer = React.lazy(() => import('../../components/footer'));


const EtgAccess = (props) => {
  const dispatch = useDispatch();
  const { push } = props;
  const authID = useSelector(selectID);
  const [search,setSearch]=useState("");
  const history = useHistory();
  const isWebScreen = useScreenSize();
  const subscription = useSelector(selectSubscriptionStatus);
  const { canPurchase, hasAppliedCoupon, status, error } = subscription;
const [isInstiution, setIsInstiution] = useState(false);
  const email = authID?.u?.["signInNames.emailAddress"];

  useEffect(() => {
    if (authID.u || authID.i) {
      if (authID.u?.authenticationSource === "localAccountAuthentication") {
        // Single & multi user
        setIsInstiution(false);
        if (email) {
          if (status === "idle" || status === "failed") {
            dispatch(fetchSubscriptionStatusThunk(email));
          }
        } else {
          // If user is not logged in, reset the status
          dispatch(resetSubscriptionStatus());
        }
      } else {
        // Institution user and no need to purchase
        setIsInstiution(true);
      }
    }
  }, [authID, email, status, dispatch]);

  useEffect(() => {
    window.addEventListener("message", (event) => {
      const { type } = event.data;

      if (type === "initial") {
        dispatch(updateState({ medium: "ReactNative" }));
      }
    });

    function isInStandaloneMode() {
        return (window.navigator.standalone) || window.matchMedia('(display-mode: standalone)').matches;
    }

    if (isInStandaloneMode()) {
      window.addEventListener('unload', function() {
          localStorage.setItem('lastVisited', window.location.pathname + window.location.search + window.location.hash);
      });

      window.addEventListener('load', function() {
          const lastVisited = localStorage.getItem('lastVisited');
          if (lastVisited) {
            history.push(lastVisited);
          }
      });
    }

  }, []);

  const openTeaser = (guidelineName) => {
    const ftxtcontenturl = `/viewTopic/?guidelinePage=${encodeURIComponent(
      guidelineName
    )}&etgAccess=true`;
    const guidelinePage = guidelineName.replace(/ /g, "_");
    const data = {
      ditaPath: `${guidelinePage}/`,
      queryString: {
        guidelinePage: encodeURIComponent(guidelineName),
        etgAccess: "true",
      },
      displayBanner: true,
    };
    dispatch(updateState(data));
    push(ftxtcontenturl);
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission
    console.log(event);
    // if (event.keyCode === 13) {
    dispatch(setSearchTerm(search));
    // submitform(event.target.value, push);
    push("/searchAction/?appendedinputbuttons=" + encodeURIComponent(search));

    // }
    setSearch("");
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSearch(value);
  };

  const handleBIClick = (event) => {
    document.getElementById("drugIndexModal").classList.add("in");
    document.getElementById("drugIndexModal").style.display = "block";

    document.querySelectorAll(".modal-backdrop").forEach((element) => {
      element.classList.add("in");
      element.style.display = "block";
    });
  };

  const renderQuickLinksAndWhatsNew = () => (
    <>
    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <h4 className="section-heading">Quick links</h4>
          <p></p>
          <ul className="list-unstyled quicklinks">
                 <li><a href="https://ccmsfiles.tg.org.au/s5/PDFs/GPSummary_v20.pdf?type=Antibiotic prescribing in primary care - summary table" title="Antibiotic prescribing in primary care - summary table" target="_blank" rel="noopener noreferrer">Antibiotic prescribing in primary care - summary table</a></li>
                 <li><a href="https://ccmsfiles.tg.org.au/s5/PDFs/ABG16-Surgical-antibiotic-prophylaxis-Collated-table-v4.pdf?type=Surgical prophylaxis - summary tables" title="Surgical prophylaxis - summary tables" rel="noopener noreferrer" target="_blank">Surgical prophylaxis - summary tables</a></li>
                 <li>
                 <HashLink role="menuitem" aria-label="Patient information and useful PDFs" to="/patientInfo/" title="Patient information and useful PDFs">Patient information and useful PDFs
                    </HashLink>
                 </li>
                 <li><HashLink role="menuitem" aria-label="Pregnancy and breastfeeding" to="/viewTopic?guidelinePage=quicklinks&etgAccess=true">
                      Pregnancy and breastfeeding
                    </HashLink></li>
                 <li><HashLink role="menuitem" aria-label="Calculators" to="/calculator/#bsacalc" title="Calculators">
                      Calculators
                    </HashLink></li>
            	 <li><HashLink role="menuitem" aria-label="Tables and figures" to="/tables-and-figures/" title="Tables and figures">
                      Tables and figures
                    </HashLink></li>
            	 </ul>
    </div>
    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 nopadding">
        <h4 className="section-heading">What's new </h4>
          <p>
          </p>
        <div id="whatsnewId">
        <p> The February 2025 release of <i>Therapeutic Guidelines </i>includes a complete revision of the following content:</p>
        <p>• <a href="#" onClick = {() => openTeaser('Psychotropic')} ><u>Psychotropic</u></a> (selected clinical topics)</p>
        <p>• <a href="#" onClick = {() => openTeaser('Cardiovascular')} ><u>Cardiovascular</u></a> (selected clinical topics)</p>
        <p>To find out what's new in this content, and for a summary of other practice-changing updates in the February 2025 release, see <a target="_blank" href="https://www.tg.org.au/content-updates/"><u>Updates</u></a>.<br /></p>
        <p> We welcome feedback from our community; please contact <a target="_blank" href="mailto:feedback@tg.org.au"><u><b>feedback@tg.org.au</b></u></a>.</p></div>
    </div>
    </>
  );

  const renderAboutTG = () => (
    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <h4 className="section-heading">About Therapeutic Guidelines</h4>
          <div id="descriptionId">
          <p></p><p><em>Therapeutic Guidelines</em> is recognised as a leading source of accurate, independent and practical treatment advice for a wide range of clinical conditions. It includes explicit instructions for therapy, assisting practitioners in making decisions to ensure their patients receive optimum treatment.</p>
          <p>Licence options&nbsp;are available to meet a wide range of needs - for single users, practices and clinics, or larger organisations.</p>
          <p><em>Therapeutic Guidelines</em> now features a new look, improved search functionality and navigation. An app for Apple and Android devices is now included in the subscription, available on and offline - ideal for having vital information at the point of care.</p><p></p>
          </div>
    </div>
  );

  const renderPurchase = () => (
    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 nopadding">
       <Purchase hasAppliedCoupon={hasAppliedCoupon} />
    </div>
  );
  

  return (
    <>
      <Suspense fallback={<div className="loader"></div>}>
        <NavBar push={push} />
        <div className="container dart-etgAccess">
          <div className="row sectionBlockLayout">
            <div className="row-container">
              <div className="col-lg-4 col-md-4 col-sm-5 columnBlockLayout">
                <div className="section-heading">
                  <h4>Guidelines</h4>
                </div>
                <ul className="list-unstyled guideline-areas">
                  <li>
                    <span className="dotIcon addictionmedicine" />
                    <a
                      alt=""
                      data-toggle="tooltip"
                      href="#"
                      onClick={() => openTeaser("Addiction Medicine")}
                    >
                      Addiction
                      <span className="goto-arrow">
                        <i className="fa banner-head-topic fa-angle-right" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <span className="dotIcon antibiotic" />
                    <a
                      alt=""
                      data-toggle="tooltip"
                      href="#"
                      onClick={() => openTeaser("Antibiotic")}
                      title=""
                    >
                      Antibiotic
                      <span className="goto-arrow">
                        <i className="fa banner-head-topic fa-angle-right" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <span className="dotIcon boneandmetabolism" />
                    <a
                      alt=""
                      data-toggle="tooltip"
                      href="#"
                      onClick={() => openTeaser("Bone and Metabolism")}
                      title=""
                    >
                      Bone and Metabolism
                      <span className="goto-arrow">
                        <i className="fa banner-head-topic fa-angle-right" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <span className="dotIcon cardiovascular" />
                    <a
                      alt=""
                      data-toggle="tooltip"
                      href="#"
                      onClick={() => openTeaser("Cardiovascular")}
                      title=""
                    >
                      Cardiovascular
                      <span className="goto-arrow">
                        <i className="fa banner-head-topic fa-angle-right" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <span className="dotIcon dermatology" />
                    <a
                      alt=""
                      data-toggle="tooltip"
                      href="#"
                      onClick={() => openTeaser("Dermatology")}
                      title=""
                    >
                      Dermatology
                      <span className="goto-arrow">
                        <i className="fa banner-head-topic fa-angle-right" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <span className="dotIcon developmentaldisability" />
                    <a
                      alt=""
                      data-toggle="tooltip"
                      href="#"
                      onClick={() => openTeaser("Developmental Disability")}
                      title=""
                    >
                      Developmental Disability
                      <span className="goto-arrow">
                        <i className="fa banner-head-topic fa-angle-right" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <span className="dotIcon diabetes" />
                    <a
                      alt=""
                      data-toggle="tooltip"
                      href="#"
                      onClick={() => openTeaser("Diabetes")}
                      title=""
                    >
                      Diabetes
                      <span className="goto-arrow">
                        <i className="fa banner-head-topic fa-angle-right" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <span className="dotIcon fatigue" />
                    <a
                      alt=""
                      data-toggle="tooltip"
                      href="#"
                      onClick={() => openTeaser("Fatigue")}
                      title=""
                    >
                      Fatigue
                      <span className="goto-arrow">
                        <i className="fa banner-head-topic fa-angle-right" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <span className="dotIcon gastrointestinal" />
                    <a
                      alt=""
                      data-toggle="tooltip"
                      href="#"
                      onClick={() => openTeaser("Gastrointestinal")}
                      title=""
                    >
                      Gastrointestinal
                      <span className="goto-arrow">
                        <i className="fa banner-head-topic fa-angle-right" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <span className="dotIcon liverdisorders" />
                    <a
                      alt=""
                      data-toggle="tooltip"
                      href="#"
                      onClick={() => openTeaser("Liver Disorders")}
                      title=""
                    >
                      Liver Disorders
                      <span className="goto-arrow">
                        <i className="fa banner-head-topic fa-angle-right" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <span className="dotIcon neurology" />
                    <a
                      alt=""
                      data-toggle="tooltip"
                      href="#"
                      onClick={() => openTeaser("Neurology")}
                      title=""
                    >
                      Neurology
                      <span className="goto-arrow">
                        <i className="fa banner-head-topic fa-angle-right" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <span className="dotIcon oralanddental" />
                    <a
                      alt=""
                      data-toggle="tooltip"
                      href="#"
                      onClick={() => openTeaser("Oral and Dental")}
                      title=""
                    >
                      Oral and Dental
                      <span className="goto-arrow">
                        <i className="fa banner-head-topic fa-angle-right" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <span className="dotIcon painandanalgesia" />
                    <a
                      alt=""
                      data-toggle="tooltip"
                      href="#"
                      onClick={() => openTeaser("Pain and Analgesia")}
                      title=""
                    >
                      Pain and Analgesia
                      <span className="goto-arrow">
                        <i className="fa banner-head-topic fa-angle-right" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <span className="dotIcon palliativecare" />
                    <a
                      alt=""
                      data-toggle="tooltip"
                      href="#"
                      onClick={() => openTeaser("Palliative Care")}
                      title=""
                    >
                      Palliative Care
                      <span className="goto-arrow">
                        <i className="fa banner-head-topic fa-angle-right" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <span className="dotIcon psychotropic" />
                    <a
                      alt=""
                      data-toggle="tooltip"
                      href="#"
                      onClick={() => openTeaser("Psychotropic")}
                      title=""
                    >
                      Psychotropic
                      <span className="goto-arrow">
                        <i className="fa banner-head-topic fa-angle-right" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <span className="dotIcon respiratory" />
                    <a
                      alt=""
                      data-toggle="tooltip"
                      href="#"
                      onClick={() => openTeaser("Respiratory")}
                      title=""
                    >
                      Respiratory
                      <span className="goto-arrow">
                        <i className="fa banner-head-topic fa-angle-right" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <span className="dotIcon rheumatology" />
                    <a
                      alt=""
                      data-toggle="tooltip"
                      href="#"
                      onClick={() => openTeaser("Rheumatology")}
                      title=""
                    >
                      Rheumatology
                      <span className="goto-arrow">
                        <i className="fa banner-head-topic fa-angle-right" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <span className="dotIcon sexualandreproductivehealth" />
                    <a
                      alt=""
                      data-toggle="tooltip"
                      href="#"
                      onClick={() =>
                        openTeaser("Sexual and Reproductive Health")
                      }
                      title=""
                    >
                      Sexual and Reproductive Health
                      <span className="goto-arrow">
                        <i className="fa banner-head-topic fa-angle-right" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <span className="dotIcon toxicologyandtoxinology" />
                    <a
                      alt=""
                      data-toggle="tooltip"
                      href="#"
                      onClick={() => openTeaser("Toxicology and Toxinology")}
                      title=""
                    >
                      Toxicology and Toxinology
                      <span className="goto-arrow">
                        <i className="fa banner-head-topic fa-angle-right" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <span className="dotIcon ulcerandwoundmanagement" />
                    <a
                      alt=""
                      data-toggle="tooltip"
                      href="#"
                      onClick={() => openTeaser("Ulcer and Wound Management")}
                      title=""
                    >
                      Ulcer and Wound Management
                      <span className="goto-arrow">
                        <i className="fa banner-head-topic fa-angle-right" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <span className="dotIcon wildernessmedicine" />
                    <a
                      alt=""
                      data-toggle="tooltip"
                      href="#"
                      onClick={() => openTeaser("Wilderness Medicine")}
                      title=""
                    >
                      Wilderness Medicine
                      <span className="goto-arrow">
                        <i className="fa banner-head-topic fa-angle-right" />
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
                  
              <div className="col-lg-8 col-md-8 col-sm-7">       
           <form id="searchformGPeTgld" name="searchformGP"  onSubmit={handleSubmit} className="hidden-xs">
<div className="input-group search-widget custom-filter">
          
            <div className="form-group">
<input type="text" name="appendedinputbuttons" id="globalserach" className="form-control input-lg ui-autocomplete-input" placeholder="Search" autoComplete="on" value={search} onChange={handleChange}  /></div>
<span className="input-group-btn"> <a className="btn nopadding" type="button" onClick={handleSubmit}> </a> </span> 
           <div className="mTop6px text-center qtn-font helpMark"></div>
          </div>
         
          
          </form>
          <div className="clearfix"></div>
        <div className="col-lg-6 mgbtm20px">
          <div className="row">
           <div id="drugnameSearch1">
{!canPurchase && <div className="BrowsedrugBtn col-lg-12 " style={{position:"relative"}}>
    <button type="button" onClick={(e)=>handleBIClick(e)} className="btn css_btn_class btn-block " data-toggle="modal" data-target="#drugIndexModal">
        Browse drug index  
    </button>
    <span className=" text-center qtn-font helpMark"></span> 
</div>}
   
 
 <div className="clearfix"></div>
</div>
          </div>
        </div>
        <div className="clearfix"></div>

        {isInstiution ? renderQuickLinksAndWhatsNew()
                    :  (status === "succeeded" || status === "idle") ? (
                                 !canPurchase  ? (
                                    renderQuickLinksAndWhatsNew()
                                  ) : (
                                    isWebScreen ? (
                                      <>
                                        {renderAboutTG()}
                                        {renderPurchase()}
                                      </>
                                    ) : (
                                      renderAboutTG()
                                    )
                                  )
                                ) : (
                                  <div>Loading...</div>
                                )}
       
    </div>
            </div>
          </div>
        </div>
        {!window.Microsoft && <Footer />}
      </Suspense>
    </>
  );
};

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
});

export default connect(mapStateToProps, { push })(EtgAccess);
