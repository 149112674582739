export const validateAlphaNumericAll = (alphanum) => {
  var reg = /^[A-Za-z0-9.\-\s!@#$%^'*()=+,]*$/;  
  if (reg.test(alphanum)) {
    return true;
  }
  else {
    return false;
  }
}

export const removeSpecialCharacters = (str) => {
  if (!str) {
    return '';
  }
  const pattern = /[^a-zA-Z0-9_\s]/g;
  const cleanStr = str.replace(pattern, '');

  return cleanStr;
};

export function getLocationUrl() {
  try {
    if (window !== window.parent) {
      return window.parent.location.toString();
    }
  } catch (e) {
    console.log('Cross-origin frame detected, using current window location');
  }
  
  return window.location.toString();
}

export function getUrlObj() {
  const url = getLocationUrl();
  try {
    const urlObj = new URL(url);
    return urlObj;
  } catch (e) {
    console.log('Error parsing URL:', e);
    return '';
  }
}
